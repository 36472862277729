import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit'

import { DEFAULT_PAGINATION } from 'shared/constants'
import { ErrorNotificationPayload } from 'shared/features/Notification/types'
import { Pageable, PaginationParams } from 'shared/types'

import { DEFAULT_GET_PARAMS_REVIEWS } from '../constants'
import { CreateReview, GetRequestParamsReviews, Review, ReviewsFilter } from '../types'

export type ReviewsState = {
  reviews: Review[]
  reviewsIsLoading: boolean
  reviewsPagination: PaginationParams
  reviewsFilter: ReviewsFilter
  createReviewIsLoading: boolean
  createReviewIsSuccess: boolean
}

const initialState: ReviewsState = {
  reviews: [],
  reviewsIsLoading: false,
  reviewsPagination: { ...DEFAULT_PAGINATION, ...DEFAULT_GET_PARAMS_REVIEWS },
  reviewsFilter: { sortByDateDesc: true },
  createReviewIsLoading: false,
  createReviewIsSuccess: false,
}

const reviews = createSlice({
  name: '@@reviews',
  initialState,
  reducers: {
    fetchReviews: (state: Draft<ReviewsState>, _action: PayloadAction<GetRequestParamsReviews>): void => {
      state.reviewsIsLoading = true
    },
    fetchReviewsSuccess: (state: Draft<ReviewsState>, action: PayloadAction<Pageable<Review>>): void => {
      const {
        payload: { content, ...pagination },
      } = action
      state.reviews = content
      state.reviewsPagination = pagination
      state.reviewsIsLoading = false
    },
    fetchReviewsError: (state: Draft<ReviewsState>): void => {
      state.reviewsIsLoading = false
    },
    createReview: (state: Draft<ReviewsState>, _action: PayloadAction<CreateReview>): void => {
      state.createReviewIsLoading = true
    },
    createReviewSuccess: (state: Draft<ReviewsState>): void => {
      state.createReviewIsLoading = false
      state.createReviewIsSuccess = true
    },
    createReviewErrorNotification: (state: Draft<ReviewsState>, _action: ErrorNotificationPayload): void => {
      state.createReviewIsLoading = false
      state.createReviewIsSuccess = false
    },
    reset: (state: Draft<ReviewsState>): void => {
      state.createReviewIsLoading = false
      state.createReviewIsSuccess = false
    },
  },
})

export const { actions, reducer } = reviews
