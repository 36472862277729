import { useRef, useCallback } from 'react'

import useEnhancedEffect from 'shared/hooks/useEnhancedEffect'

/**
 * https://github.com/facebook/react/issues/14099#issuecomment-440013892
 *
 * @param {function} fn
 */
export default function useEventCallback(fn: (node: HTMLDivElement) => void): (...args: []) => void {
  const ref = useRef(fn)
  useEnhancedEffect(() => {
    ref.current = fn
  })
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return useCallback((...args) => (0, ref.current)(...args), [])
}
