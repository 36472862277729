import React from 'react'

import useNotification from '../../hooks/useNotification'
import { NotificationModal } from '../NotificationModal'

export const Notification = () => {
  const { notification, prevent } = useNotification()

  if (!notification) {
    return null
  }

  return <NotificationModal onClose={prevent} {...notification} />
}
