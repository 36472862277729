import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pageable, PaginationParams, User } from 'shared/types'
import { DEFAULT_PAGINATION, Platform } from 'shared/constants'
import {
  GetRequestParamsOrders,
  Total,
  OrdersFilter,
  UpdateEmailAction,
  UpdateNameSurnameAction,
  UpdatePasswordAction,
} from 'shared/features/Profile/typings'
import ProfileService from 'shared/features/Profile/ProfileService'
import { CreateOrderResponse } from 'shared/features/Buy/types'

import { DEFAULT_GET_PARAMS_ORDERS, DEFAULT_ORDERS_FILTER } from '../constants'

export type ProfileState = {
  user?: User
  platform: Platform
  friends: []
  squadIsRemovedSuccess: boolean
  isLoading: boolean
  errors: string
  status: string
  showUsedAccountModal: boolean
  orders: CreateOrderResponse[]
  ordersIsLoading: boolean
  ordersFilter: Partial<OrdersFilter>
  ordersPagination: PaginationParams
  total: Total | null
  totalIsLoading: boolean
}

const initialState: ProfileState = {
  user: undefined,
  platform: ProfileService.platform || Platform.PC,
  friends: [],
  squadIsRemovedSuccess: false,
  isLoading: false,
  errors: '',
  status: '',
  showUsedAccountModal: false,
  orders: [],
  ordersIsLoading: false,
  ordersFilter: DEFAULT_ORDERS_FILTER,
  ordersPagination: { ...DEFAULT_PAGINATION, ...DEFAULT_GET_PARAMS_ORDERS },
  total: null,
  totalIsLoading: false,
}

const userSlice = createSlice({
  name: '@@user',
  initialState,
  reducers: {
    getAccount: (state: Draft<ProfileState>): void => {
      state.isLoading = true
    },
    getAccountSuccess: (state: Draft<ProfileState>, action: PayloadAction<User>): void => {
      state.isLoading = false
      state.user = action.payload
    },
    getAccountError: (state: Draft<ProfileState>): void => {
      state.isLoading = false
    },
    setAccountPlatform: (state: Draft<ProfileState>, _action: PayloadAction<Platform>): ProfileState => state,
    setAccountPlatformSuccess: (state: Draft<ProfileState>, action: PayloadAction<Platform>): void => {
      state.platform = action.payload
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setAccountPlatformError: (): void => {},
    updateEmail: (state: ProfileState, _action: PayloadAction<UpdateEmailAction>): ProfileState => ({
      ...state,
      isLoading: true,
    }),
    updateEmailSuccess: (state: ProfileState, action: PayloadAction<User>): ProfileState => ({
      ...state,
      isLoading: false,
      user: {
        ...state.user,
        ...action.payload,
      },
    }),
    updatePassword: (state: ProfileState, _action: PayloadAction<UpdatePasswordAction>): ProfileState => ({
      ...state,
      isLoading: true,
    }),
    updatePasswordSuccess: (state: ProfileState, action: PayloadAction<User>): ProfileState => ({
      ...state,
      isLoading: false,
      user: {
        ...state.user,
        ...action.payload,
      },
    }),
    updateNameSurname: (state: ProfileState, _action: PayloadAction<UpdateNameSurnameAction>): ProfileState => ({
      ...state,
      isLoading: true,
    }),
    updateNameSurnameSuccess: (state: ProfileState, action: PayloadAction<User>): ProfileState => ({
      ...state,
      isLoading: false,
      user: action.payload,
    }),
    setUpdateStatus: (state: ProfileState, status: PayloadAction<string>): ProfileState => ({
      ...state,
      isLoading: false,
      status: status.payload,
    }),
    setUpdateError: (state: ProfileState, errors: PayloadAction<string>): ProfileState => ({
      ...state,
      isLoading: false,
      errors: errors.payload,
    }),
    resetPersonalContent: (state: ProfileState): ProfileState => {
      const { user, platform } = state
      return { ...initialState, user, platform }
    },
    reset: (): ProfileState => initialState,
    resetErrors: (state: ProfileState): ProfileState => ({ ...state, errors: '' }),
    resetStatus: (state: ProfileState): ProfileState => ({ ...state, status: '' }),
    resetUser: (state: Draft<ProfileState>): void => {
      state.user = initialState.user
    },
    setOrdersFilter: (state: ProfileState, action: PayloadAction<Partial<OrdersFilter>>) => {
      state.ordersFilter = action.payload
    },
    getOrders: (state: ProfileState, _action: PayloadAction<GetRequestParamsOrders>): void => {
      state.ordersIsLoading = true
    },
    getOrdersSuccess: (state: ProfileState, action: PayloadAction<Pageable<CreateOrderResponse>>): void => {
      const {
        payload: { content, ...pagination },
      } = action
      state.orders = content
      state.ordersPagination = pagination
      state.ordersIsLoading = false
    },
    getOrdersError: (state: ProfileState): void => {
      state.ordersIsLoading = false
    },
    getTotal: (state: ProfileState): void => {
      state.totalIsLoading = true
    },
    getTotalSuccess: (state: ProfileState, action: PayloadAction<Total>): void => {
      state.total = action.payload
      state.totalIsLoading = false
    },
    getTotalError: (state: ProfileState): void => {
      state.totalIsLoading = false
    },
  },
})

export const { reducer, actions } = userSlice
