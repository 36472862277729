import { RouteConfig } from 'react-router-config'
import loadable from '@loadable/component'

import * as routes from 'shared/constants/routes'
import { Route } from 'shared/types'
import { Permissions } from 'shared/constants'
import Widget from 'shared/features/Widget'
import InternalError from 'shared/features/Errors/views/InternalError'
import NotFound from 'shared/features/Errors/views/NotFound'

const routing: Array<RouteConfig & Route> = [
  {
    ...routes.admin,
    component: loadable(() => import(/* webpackChunkName: "Profile" */ 'shared/features/Admin')),
    private: true,
    permissions: [Permissions.ADMIN],
  },
  {
    ...routes.profile,
    component: loadable(() => import(/* webpackChunkName: "Profile" */ 'shared/features/Profile')),
    private: true,
  },
  {
    ...routes.login,
    component: loadable(() => import(/* webpackChunkName: "Auth" */ 'shared/features/Auth/views/Login')),
  },
  {
    ...routes.registration,
    component: loadable(() => import(/* webpackChunkName: "Auth" */ 'shared/features/Auth/views/Registration')),
  },
  {
    ...routes.passwordRecovery,
    component: loadable(() => import(/* webpackChunkName: "Auth" */ 'shared/features/Auth/views/PasswordRecovery')),
  },
  {
    ...routes.passwordNew,
    component: loadable(() => import(/* webpackChunkName: "Auth" */ 'shared/features/Auth/views/NewPassword')),
  },
  {
    ...routes.widget,
    component: Widget,
    exact: true,
  },
  {
    ...routes.home,
    component: loadable(() => import(/* webpackChunkName: "App" */ 'shared/features/Home')),
    exact: true,
  },
  {
    ...routes.buy,
    component: loadable(() => import(/* webpackChunkName: "App" */ 'shared/features/Buy')),
    exact: true,
  },
  {
    ...routes.reviews,
    component: loadable(() => import(/* webpackChunkName: "App" */ 'shared/features/Reviews')),
    exact: true,
  },
  {
    ...routes.privacy,
    component: loadable(() => import(/* webpackChunkName: "App" */ 'shared/features/Documents/views/Privacy')),
    exact: true,
  },
  {
    ...routes.terms,
    component: loadable(() => import(/* webpackChunkName: "App" */ 'shared/features/Documents/views/Terms')),
    exact: true,
  },
  {
    ...routes.contacts,
    component: loadable(() => import(/* webpackChunkName: "App" */ 'shared/features/Documents/views/Contacts')),
    exact: true,
  },
  {
    ...routes.internalServerError,
    component: InternalError,
    status: 500,
  },
  {
    ...routes.notFound,
    component: NotFound,
    status: 404,
  },
]

export default routing
