import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ErrorNotificationPayload, SuccessNotificationPayload } from 'shared/features/Notification/types'

import { LoginPayload, NewPasswordRequest, ReactivationPayload, RegistrationRequest } from '../typings'

export type AuthState = {
  isLoading: boolean
  loginIsloading: boolean
  activationIsloading: boolean
  registrationIsloading: boolean
  reactivationIsloading: boolean
  resetPasswordIsloading: boolean
  isReactivated: boolean
  registrationEmail: string | null
  isAvailableEmail: boolean
  isAvailableLogin: boolean
  isNewPassword: boolean
}

const initialState: AuthState = {
  isLoading: false,
  loginIsloading: false,
  activationIsloading: false,
  registrationIsloading: false,
  reactivationIsloading: false,
  resetPasswordIsloading: false,
  isReactivated: false,
  registrationEmail: null,
  isAvailableEmail: true,
  isAvailableLogin: true,
  isNewPassword: false,
}

const auth = createSlice({
  name: '@@auth',
  initialState,
  reducers: {
    login: (state, _action: PayloadAction<LoginPayload>) => {
      state.loginIsloading = true
    },
    authErrorNotification: (state, _action: ErrorNotificationPayload) => {
      state.isLoading = false
    },
    loginSuccess: state => {
      state.loginIsloading = false
    },
    loginErrorNotification: (state, _action: ErrorNotificationPayload) => {
      state.loginIsloading = false
    },
    activation: (state, _action: PayloadAction<string>) => {
      state.activationIsloading = true
    },
    activationSuccessNotification: (state, _action: SuccessNotificationPayload) => {
      state.activationIsloading = false
    },
    activationErrorNotification: (state, _action: ErrorNotificationPayload) => {
      state.activationIsloading = false
    },
    registration: (state, _action: PayloadAction<RegistrationRequest>) => {
      state.registrationIsloading = true
    },
    registrationSuccessNotification: (state, action: SuccessNotificationPayload<{ email: string }>) => {
      state.registrationIsloading = false
      state.registrationEmail = action.payload.data.email
    },
    registrationErrorNotification: (state, _action: ErrorNotificationPayload) => {
      state.registrationIsloading = false
    },
    checkFailed: state => {
      state.registrationIsloading = false
    },
    reactivation: (state, _action: PayloadAction<ReactivationPayload>) => {
      state.reactivationIsloading = true
    },
    reactivationSuccessNotification: (state, _action: SuccessNotificationPayload) => {
      state.reactivationIsloading = false
      state.isReactivated = true
    },
    reactivationErrorNotification: (state, _action: ErrorNotificationPayload) => {
      state.reactivationIsloading = false
    },
    resetPassword: (state, _action: PayloadAction<string>) => {
      state.resetPasswordIsloading = true
    },
    resetPasswordSuccessNotification: (state, _action: SuccessNotificationPayload) => {
      state.resetPasswordIsloading = false
    },
    resetPasswordErrorNotification: (state, _action: ErrorNotificationPayload) => {
      state.resetPasswordIsloading = false
    },
    newPassword: (state, _action: PayloadAction<NewPasswordRequest>) => {
      state.isLoading = true
    },
    newPasswordSuccessNotification: (state, action: SuccessNotificationPayload<{ isNewPassword: boolean }>) => {
      state.isLoading = false
      state.isNewPassword = action.payload.data.isNewPassword
    },
    checkRecoveryErrorNotification: (state, _action: ErrorNotificationPayload) => {
      state.resetPasswordIsloading = false
    },
    setIsAvailableEmail: (state, action: PayloadAction<boolean>) => {
      state.isAvailableEmail = action.payload
    },
    setIsAvailableLogin: (state, action: PayloadAction<boolean>) => {
      state.isAvailableLogin = action.payload
    },
  },
})

export const { actions, reducer } = auth
