import React from 'react'
import cn from 'clsx'

import Dialog, { DialogProps, DialogSize } from 'shared/components/Dialog'
import Typography from 'shared/components/Typography'
import useTimeout from 'shared/hooks/useTimeout'

import { Notice } from '../../types'

import styles from './NotificationModal.module.css'
import { getIcon } from './__base/getIcon'

type Props = DialogProps &
  Notice & {
    onClose: () => void
  }

export const NotificationModal: React.FC<Props> = ({
  size = DialogSize.small,
  message,
  type,
  delay = 3000,
  onClose,
  ...rest
}) => {
  useTimeout(onClose, delay)
  const Icon = getIcon(type)

  const iconCn = cn(styles.body_icon, styles[`body_icon_${type}`])

  return (
    <Dialog open size={size} classNameModal={styles.modal} onClose={onClose} {...rest}>
      <div className={styles.body}>
        <Icon className={iconCn} />
        <Typography size="m" weight="light" className={styles.body_message}>
          {message}
        </Typography>
      </div>
      <span className={styles.load} style={{ animationDuration: `${delay}ms` }} />
    </Dialog>
  )
}
