import React from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as LogoIcon } from 'shared/assets/icons/logo.svg'

import styles from './Header.module.css'

export const HeaderLogo = () => (
  <div className={styles.logo}>
    <Link to="/">
      <LogoIcon />
    </Link>
  </div>
)
