import { useEffect, useRef } from 'react'

export default (callback: () => void, delay: number) => {
  const timeoutRef = useRef<NodeJS.Timeout>()
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => savedCallback.current()
    if (delay) {
      timeoutRef.current = setTimeout(tick, delay)
    }
    return () => clearTimeout(timeoutRef.current)
  }, [delay])
}
