import { useDispatch, useSelector } from 'react-redux'

import { actions } from '../ducks'
import { getNotification } from '../ducks/selectors'
import { Notice } from '../types'

type NotificationHook = {
  notification: Notice | null
  prevent: () => void
}

export default (): NotificationHook => {
  const dispatch = useDispatch()
  const notification = useSelector(getNotification)

  const prevent = () => {
    dispatch(actions.setNotification(null))
  }

  return {
    notification,
    prevent,
  }
}
