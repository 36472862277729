import React from 'react'
import cn from 'clsx'

import styles from './Backdrop.module.css'

interface BackdropProps {
  open?: boolean
  invisible?: boolean
  className?: string
  style?: React.CSSProperties
  children?: React.ReactChildren | React.ReactElement | string
  onClick?(event: React.MouseEvent<HTMLElement, MouseEvent>): void
}

const Backdrop: React.FC<BackdropProps> = ({
  children,
  invisible = false,
  onClick,
  style,
  className,
}): React.ReactElement => (
  <div
    className={cn(
      styles.backdrop,
      {
        [styles.backdrop_invisible]: invisible,
      },
      className,
    )}
    style={style}
    onClick={onClick}
    role="presentation"
  >
    {children}
  </div>
)

export default Backdrop
