import React, { useMemo, memo } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, RouteProps } from 'react-router-dom'

import { getAuthorized, getPermissions } from 'shared/features/Profile/ducks/selectors'
import { login } from 'shared/constants/routes'
import { Permissions, REDIRECT_SEARCH_KEY } from 'shared/constants'
import Typography from 'shared/components/Typography'

interface PrivateRouteProps extends RouteProps {
  permissions?: Array<Permissions>
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ permissions, ...props }): React.ReactElement => {
  const isAuthorized = useSelector(getAuthorized)
  const userPermissions = useSelector(getPermissions)

  const hasAccess = useMemo(() => {
    if (!permissions || !permissions.length) {
      return true
    }

    return userPermissions.every((permission: Permissions) => permissions.includes(permission))
  }, [userPermissions, permissions])

  if (isAuthorized && hasAccess) {
    return <Route {...props} />
  }

  if (isAuthorized && !hasAccess) {
    return (
      <Typography style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Нет доступа!</Typography>
    )
  }

  return (
    <Redirect
      to={{
        pathname: login.path,
        search: `${REDIRECT_SEARCH_KEY}=${props?.location?.pathname}`,
      }}
    />
  )
}

export default memo(PrivateRoute)
