/* eslint-disable no-underscore-dangle */

import { AppState } from 'shared/store/reducer'

declare global {
  interface Window {
    __INITIAL_STATE__: AppState
  }
}

export default (): Partial<AppState> => {
  return window.__INITIAL_STATE__ ? window.__INITIAL_STATE__ : {}
}
