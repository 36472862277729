export const RECONNECTING_TRIES = 5
export const RECONNECTING_INTERVAL_MS = 500

export enum SendMethod {
  order = 'order',
  begin = 'begin',
  searchPlayer = 'search_player',
  nextStep = 'next_step',
  clearLots = 'clear_lots',
  searchAuctions = 'search_auctions',
  selectTrade = 'select_trade',
  cancel = 'cancel',
  findAnotherPlayer = 'find_another_player',
  cmdStartDelivery = 'cmd.start.delivery',
  cmdStopDelivery = 'cmd.stop.delivery',
}

export enum MessageMethod {
  updateOrder = 'update_order',
  orderCanceled = 'order_canceled',
}

export enum NextStepStatus {
  selling = 'selling',
}

export enum MessageType {
  params = 'params',
  onopen = 'onopen',
}
