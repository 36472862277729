import { call, put, takeLatest } from 'redux-saga/effects'

import { errorMessageHandler, getErrorNotice, getSuccessNotice } from 'shared/features/Notification/utils'

import AuthRepository from '../AuthRepository'
import { CheckLoginEmailResponse, LoginResponse, RegistrationResponse } from '../typings'
import AuthService from '../AuthService'
import { AuthErrorMessage, AuthSuccessMessage } from '../constants'
import { actions as profileActions } from '../../Profile/ducks'

import { actions } from '.'

function* login(action: ReturnType<typeof actions.login>) {
  try {
    const tokens: LoginResponse = yield call(AuthRepository.login, action.payload)
    AuthService.setToken(tokens)
    yield put(actions.loginSuccess())
    yield put(profileActions.getAccount())
  } catch (error) {
    yield put(actions.loginErrorNotification(errorMessageHandler(error)))
  }
}

function* activation(action: ReturnType<typeof actions.activation>) {
  try {
    yield call(AuthRepository.activation, action.payload)
    yield put(actions.activationSuccessNotification(getSuccessNotice(AuthSuccessMessage.Activation)))
  } catch (error) {
    yield put(actions.activationErrorNotification(getErrorNotice(AuthErrorMessage.Activation)))
  }
}

function* reactivation(action: ReturnType<typeof actions.reactivation>) {
  try {
    yield call(AuthRepository.reactivation, action.payload)
    yield put(actions.reactivationSuccessNotification(getSuccessNotice(AuthSuccessMessage.Reactivation)))
  } catch (error) {
    yield put(actions.reactivationErrorNotification(getErrorNotice(AuthErrorMessage.Reactivation)))
  }
}

function* registration(action: ReturnType<typeof actions.registration>) {
  try {
    const { isAvailable: isAvailableEmail }: CheckLoginEmailResponse = yield call(
      AuthRepository.checkEmail,
      action.payload.email,
    )
    const { isAvailable: isAvailableLogin }: CheckLoginEmailResponse = yield call(
      AuthRepository.checkLogin,
      action.payload.login,
    )

    yield put(actions.setIsAvailableEmail(isAvailableEmail))
    yield put(actions.setIsAvailableLogin(isAvailableLogin))

    if (!isAvailableEmail || !isAvailableLogin) {
      yield put(actions.checkFailed())
      return
    }

    const { email }: RegistrationResponse = yield call(AuthRepository.registration, action.payload)
    yield put(
      actions.registrationSuccessNotification({
        data: {
          email,
        },
        ...getSuccessNotice(AuthSuccessMessage.Registration),
      }),
    )
  } catch (error) {
    yield put(actions.registrationErrorNotification(getErrorNotice(AuthErrorMessage.Registration)))
  }
}

function* resetPassword(action: ReturnType<typeof actions.resetPassword>) {
  try {
    const { isAvailable }: CheckLoginEmailResponse = yield call(AuthRepository.checkRecovery, action.payload)

    if (!isAvailable) {
      yield put(actions.checkRecoveryErrorNotification(getErrorNotice(AuthErrorMessage.CheckRecovery)))
    }
  } catch (error) {
    yield put(actions.resetPasswordErrorNotification(getErrorNotice(AuthErrorMessage.ResetPassword)))
  }
}

function* newPassword(action: ReturnType<typeof actions.newPassword>) {
  try {
    yield call(AuthRepository.newPassword, action.payload)
    yield put(
      actions.newPasswordSuccessNotification({
        data: {
          isNewPassword: true,
        },
        ...getSuccessNotice(AuthSuccessMessage.NewPassword),
      }),
    )
  } catch (error) {
    yield put(actions.authErrorNotification(getErrorNotice(AuthErrorMessage.NewPassword)))
  }
}

export default function* actionWatcher() {
  yield takeLatest(actions.login, login)
  yield takeLatest(actions.activation, activation)
  yield takeLatest(actions.registration, registration)
  yield takeLatest(actions.reactivation, reactivation)
  yield takeLatest(actions.resetPassword, resetPassword)
  yield takeLatest(actions.newPassword, newPassword)
}
