import { YandexAdsConfig } from 'shared/ads/YandexAds'

export const adsUserProfile: Array<YandexAdsConfig> = [
  {
    blockId: 'R-A-682818-31',
    renderTo: 'yandex_rtb_R-A-682818-31',
  },
  {
    blockId: 'R-A-682818-32',
    renderTo: 'yandex_rtb_R-A-682818-32',
  },
  {
    blockId: 'R-A-682818-33',
    renderTo: 'yandex_rtb_R-A-682818-33',
  },
  {
    blockId: 'R-A-682818-34',
    renderTo: 'yandex_rtb_R-A-682818-34',
  },
]

export const adsAuthLayoutLeft: YandexAdsConfig = {
  blockId: 'R-A-682818-37',
  renderTo: 'yandex_rtb_R-A-682818-37',
}

export const adsAuthLayoutRight: YandexAdsConfig = {
  blockId: 'R-A-682818-20',
  renderTo: 'yandex_rtb_R-A-682818-20',
}

export const newsListWidget: YandexAdsConfig = {
  blockId: 'C-A-682818-39',
  renderTo: 'id-C-A-682818-39',
}
