import axios from 'shared/utils/axios'

import {
  CancelOrderRequest,
  CreateOrderRequest,
  CreateOrderResponse,
  PaymentRequest,
  ExchangeRatesResponse,
  PaymentResponse,
} from './types'

export default class BuyRepository {
  private static endpointDelivery = `/v1/core/delivery`

  public static createOrder = async (payload: CreateOrderRequest) => {
    const { data } = await axios.post<CreateOrderResponse>(`${BuyRepository.endpointDelivery}`, payload)

    return data
  }

  public static fetchLastOrder = async () => {
    const { data } = await axios.get<CreateOrderResponse>(`${SERVER_URL}/personal/profile/delivery/active`)

    return data
  }

  public static cancelOrder = async (payload: CancelOrderRequest) => {
    const { data } = await axios.patch<CreateOrderResponse>(`${SERVER_URL}/personal/profile/orders/cancel`, payload)

    return data
  }

  public static fetchExchangeRates = async (): Promise<ExchangeRatesResponse> => {
    const { data } = await axios.get<ExchangeRatesResponse>(`${SERVER_URL}/api/currency`)

    return data
  }

  public static createPayment = async (payload: PaymentRequest) => {
    const { data } = await axios.post<PaymentResponse>(`${SERVER_URL}/api/payments`, payload)

    return data
  }

  public static updatePayment = async (payload: PaymentRequest) => {
    const { data } = await axios.patch<PaymentResponse>(`${SERVER_URL}/api/payments`, payload)

    return data
  }

  public static actualizePayment = async (id: string) => {
    const { data } = await axios.patch<PaymentResponse>(`${SERVER_URL}/api/payments/${id}/actualize`)

    return data
  }

  public static cancelPayment = async (id: string) => {
    const { data } = await axios.patch<PaymentResponse>(`${SERVER_URL}/api/payments/${id}/cancel`)

    return data
  }
}
