import round from 'lodash.round'
import { createSelector } from 'reselect'

import { MessageMethod } from 'shared/features/WebSocket/constants'
import { AppState } from 'shared/store/reducer'

import { BuyStatus, BuyPlatform, StepName, LotStatus, PaymentStatus, PaymentError } from '../constants'
import { BuyInfo, ExchangeRatesResponse, Lot, Order, PaymentResponse, RequiredPlayer, Step } from '../types'
import { getTradesByLotStatus } from '../utils/getTradesByLotStatus'
import mapDataToExchangeRates from '../utils/mapDataToExchangeRates'

export const getAmount = (state: AppState): number => state.buy.amount
export const getInitialBalance = (state: AppState): number => state.buy.initialBalance
export const getBuyPlatform = (state: AppState): BuyPlatform => state.buy.platform
export const getExchangeRates = (state: AppState): ExchangeRatesResponse | null => state.buy.exchangeRates
export const getBuyInfo = (state: AppState): BuyInfo | null => state.buy.buyInfo
export const getMessageMethod = (state: AppState): MessageMethod | null => state.buy.messageMethod
export const getLastOrder = (state: AppState): Order | null => state.buy.lastOrder
export const getLastOrderError = (state: AppState): boolean => state.buy.lastOrderError
export const getIsPreloadInfoCompleted = (state: AppState): boolean => state.buy.isPreloadInfoCompleted
export const getCreateOrderIsLoading = (state: AppState): boolean => state.buy.createOrderIsLoading
export const getCancelOrderIsLoading = (state: AppState): boolean => state.buy.cancelOrderIsLoading
export const getPayment = (state: AppState): PaymentResponse | null => state.buy.payment
export const getPaymentUrl = (state: AppState): string | null => state.buy.paymentUrl
export const getPaymentIsLoading = (state: AppState): boolean => state.buy.paymentIsLoading
export const getSteps = (state: AppState): Step[] => state.buy.steps
export const getActiveStep = (state: AppState): StepName => state.buy.activeStep
export const getIsStartSelling = (state: AppState): boolean => state.buy.isStartSelling

export const getIsErrorInitialBalance = createSelector(getInitialBalance, initialBalance => {
  return initialBalance < MIN_INITIAL_BALANCE
})

export const getIsErrorAmount = createSelector(getAmount, amount => {
  return amount < MIN_COINS_AMOUNT
})

const getExchangeRate = createSelector([getExchangeRates, getBuyPlatform], (exchangeRates, platform): number => {
  if (!exchangeRates) {
    return 0
  }
  return mapDataToExchangeRates(exchangeRates)[platform]
})

export const getMaxCoinsAmount = createSelector(getInitialBalance, initialBalance =>
  initialBalance < MIN_RECOMENDED_INITIAL_BALANCE ? MAX_COINS_AMOUNT : MAX_EXTENDED_COINS_AMOUNT,
)

export const getPrice = createSelector([getAmount, getExchangeRate], (amount, exchangeRate): number =>
  round(amount / exchangeRate, 2),
)

export const getExchangeFutopointRate = createSelector(getExchangeRates, (exchangeRates): number => {
  if (!exchangeRates) {
    return 0
  }
  return exchangeRates.futopoint
})

export const getIsOrderPaid = createSelector(getPayment, (payment): boolean => {
  return (
    !!payment &&
    ((!!payment.moneyPayment && payment.moneyPayment.status === PaymentStatus.Success) ||
      (!!payment.ftpPayment && payment.ftpPayment.paid))
  )
})

export const getIsPaymentError = createSelector(getPayment, (payment): boolean => {
  const status = payment?.moneyPayment?.status
  return (
    !!status &&
    (status === PaymentStatus.Error || status === PaymentStatus.ErrorCheck || status === PaymentStatus.ErrorPay)
  )
})

export const getPaymentErrorMessage = createSelector(getPayment, (payment): string => {
  return payment?.moneyPayment?.errorMessage || PaymentError.Transaction
})

export const getOrderUuid = createSelector(getLastOrder, (order): string => {
  return order?.uuid || ''
})

export const getIsOrderCanceled = createSelector(getMessageMethod, (method): boolean => {
  return method === MessageMethod.orderCanceled
})

/** Any of these selectors will only be called if Payment is not Null */

export const getAmountBought = createSelector([getBuyInfo, getLastOrder], (buyInfo, lastOrder): number => {
  return buyInfo?.amount_bought || lastOrder?.amountBought || 0
})

/** Any of these selectors will only be called if Order is not Null */

const getNotNullableLastOrder = createSelector(getLastOrder, order => order as Order)

export const getOrderPrice = createSelector(getNotNullableLastOrder, (order): number => {
  return order.priceRub
})

export const getOrderInitialBalance = createSelector(getNotNullableLastOrder, (order): number => {
  return order.initialBalance
})

export const getOrderId = createSelector(getNotNullableLastOrder, (order): number => {
  return order.deliveryId
})

export const getAmountNeeded = createSelector(getNotNullableLastOrder, (order): number => {
  return order.amountNeeded
})

export const getBuyInfoPlatform = createSelector(
  getNotNullableLastOrder,
  (order): BuyPlatform => {
    return order.platform
  },
)

/** Any of these selectors will only be called if BuyInfo is not Null */

export const getBuyStatus = createSelector(getBuyInfo, (buyInfo): BuyStatus | undefined => buyInfo?.status)

export const getRecommendedBuyPrice = createSelector(getBuyInfo, (buyInfo): number => {
  const {
    strategy: { recommended_buy_price },
  } = buyInfo as BuyInfo

  return recommended_buy_price
})

export const getStartingBid = createSelector(getBuyInfo, (buyInfo): number => {
  const {
    strategy: { starting_bid },
  } = buyInfo as BuyInfo

  return starting_bid
})

export const getBuyNowPrice = createSelector(getBuyInfo, (buyInfo): number => {
  const {
    strategy: { buy_now_price },
  } = buyInfo as BuyInfo

  return buy_now_price
})

export const getRequiredPlayersCount = createSelector(getBuyInfo, (buyInfo): 1 | 2 => {
  const {
    strategy: { required_players_count },
  } = buyInfo as BuyInfo

  return required_players_count
})

export const getRequiredPlayer = createSelector(getBuyInfo, (buyInfo): RequiredPlayer | null => {
  const {
    strategy: { required_player },
  } = buyInfo as BuyInfo

  return required_player
})

export const getLots = createSelector(getBuyInfo, (buyInfo): Lot[] => {
  const { lots } = buyInfo as BuyInfo

  return lots
})

export const getBoughtTrades = createSelector(getLots, lots => getTradesByLotStatus(lots, LotStatus.bought))
