import { call, put, takeLatest } from 'redux-saga/effects'

import { actions } from 'shared/features/Moderator/ducks'
import AuthService from 'shared/features/Auth/AuthService'
import ModeratorRepository from 'shared/features/Moderator/ModeratorRepository'

function* addComplaint(action: ReturnType<typeof actions.addComplaint>) {
  try {
    if (AuthService.hasToken()) {
      yield call(ModeratorRepository.addComplaint, action.payload)
      yield put(actions.addComplaintSuccess())
    }
  } catch (e) {
    yield put(actions.addComplaintError())
  }
}

export default function* actionWatcher() {
  yield takeLatest(actions.addComplaint.type, addComplaint)
}
