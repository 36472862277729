import { call, put, select, takeLatest } from 'redux-saga/effects'

import { Pageable, User } from 'shared/types'
import { ERRORS, STATUSES } from 'shared/constants/profile'
import ProfileRepository from 'shared/features/Profile/ProfileRepository'
import AuthService from 'shared/features/Auth/AuthService'
import { Platform } from 'shared/constants'
import ProfileService from 'shared/features/Profile/ProfileService'
import { CreateOrderResponse } from 'shared/features/Buy/types'

import { GetRequestParamsOrders, Total } from '../typings'

import { getAuthorized, getUser } from './selectors'

import { actions } from './index'

function* getAccount() {
  try {
    if (AuthService.hasToken()) {
      const user: User = yield call(ProfileRepository.getAccount)

      const picture = user.picture ? `${user.picture}?${new Date().getTime()}` : undefined

      yield put(actions.getAccountSuccess({ ...user, picture }))
      yield put(actions.setAccountPlatformSuccess(user.platform || Platform.PC))
      ProfileService.platform = user.platform
    }
  } catch (e) {
    yield put(actions.getAccountError())
  }
}

function* updateEmail(action: ReturnType<typeof actions.updateEmail>) {
  try {
    if (AuthService.hasToken()) {
      const user: User = yield call(ProfileRepository.updateEmail, action.payload)

      yield put(actions.updateEmailSuccess(user))
      yield put(actions.setUpdateStatus(STATUSES.email_update_success))
      yield call(ProfileRepository.getAccount)
    }
  } catch (e) {
    if (e.response.status === 403) {
      yield put(actions.setUpdateError(ERRORS.wrong_password))
    }
    if (e.response.status === 500) {
      yield put(actions.setUpdateError(ERRORS.something_wrong))
    }
    if (e.response.status === 400) yield put(actions.setUpdateError(ERRORS.email_is_used))
  }
}

function* updatePassword(action: ReturnType<typeof actions.updatePassword>) {
  try {
    if (AuthService.hasToken()) {
      const user: User = yield call(ProfileRepository.updatePassword, action.payload)

      yield put(actions.updatePasswordSuccess(user))
      yield put(actions.setUpdateStatus(STATUSES.password_update_success))
      yield call(ProfileRepository.getAccount)
    }
  } catch (e) {
    if (e.response.status === 403) {
      yield put(actions.setUpdateError(ERRORS.wrong_password))
    } else {
      yield put(actions.setUpdateError(ERRORS.something_wrong))
    }
  }
}

function* updateNameSurname(action: ReturnType<typeof actions.updateNameSurname>) {
  try {
    if (AuthService.hasToken()) {
      const user: User = yield call(ProfileRepository.updateNameSurname, action.payload)

      yield put(actions.updateNameSurnameSuccess(user))
      yield put(actions.setUpdateStatus(STATUSES.name_update_success))
      yield call(ProfileRepository.getAccount)
    }
  } catch (e) {
    yield put(actions.setUpdateError(ERRORS.something_wrong))
  }
}

function* setAccountPlatform(action: ReturnType<typeof actions.setAccountPlatform>) {
  try {
    const isAuthorized: boolean = yield select(getAuthorized)

    if (isAuthorized) {
      const platform: Platform = yield call(ProfileRepository.updatePlatform, action.payload)

      yield put(actions.setAccountPlatformSuccess(platform))
      ProfileService.platform = platform
    } else {
      yield put(actions.setAccountPlatformSuccess(action.payload))

      ProfileService.platform = action.payload
    }
  } catch (e) {
    yield put(actions.setAccountPlatformError())
  }
}

function* getOrders(action: ReturnType<typeof actions.getOrders>) {
  try {
    const user: ReturnType<typeof getUser> = yield select(getUser)
    if (!user) {
      return
    }

    const params: GetRequestParamsOrders = {
      ...action.payload,
      userLogin: user.login,
    }

    const response: Pageable<CreateOrderResponse> = yield call(ProfileRepository.getOrders, params)

    yield put(actions.getOrdersSuccess(response))
  } catch (e) {
    yield put(actions.getOrdersError())
  }
}

function* getTotal() {
  try {
    const response: Total = yield call(ProfileRepository.getTotal)

    yield put(actions.getTotalSuccess(response))
  } catch (e) {
    yield put(actions.getTotalError())
  }
}

export default function* actionWatcher() {
  yield takeLatest(actions.getAccount.type, getAccount)
  yield takeLatest(actions.updateEmail.type, updateEmail)
  yield takeLatest(actions.updatePassword.type, updatePassword)
  yield takeLatest(actions.setAccountPlatform.type, setAccountPlatform)
  yield takeLatest(actions.updateNameSurname.type, updateNameSurname)
  yield takeLatest(actions.getOrders, getOrders)
  yield takeLatest(actions.getTotal, getTotal)
}
