import React from 'react'
import cn from 'clsx'

import styles from './Dialog.module.css'

interface DialogContentProps {
  children: React.ReactElement | React.ReactNode | string | Array<React.ReactElement | React.ReactNode | string>
  className?: string
}

const DialogContent: React.FC<DialogContentProps> = ({ children, className }): React.ReactElement => (
  <div className={cn(styles.dialog__content, className)}>{React.Children.toArray(children)}</div>
)

export default DialogContent
