import { Route } from 'shared/types'
import { ReactComponent as EventsIcon } from 'shared/assets/icons/events.svg'
import { ReactComponent as HomeIcon } from 'shared/assets/icons/home.svg'
import { ReactComponent as SettingsIcon } from 'shared/assets/icons/settings.svg'

export const home: Route = {
  title: 'Главная',
  path: '/',
  icon: HomeIcon,
}

export const buy: Route = {
  title: 'Покупка монет',
  path: '/buy',
}

export const reviews: Route = {
  title: 'Отзывы',
  path: '/reviews',
}

export const profile: Route = {
  title: 'Личный кабинет',
  path: '/profile',
  icon: SettingsIcon,
}

export const profileOrders: Route = {
  title: 'История заказов',
  path: '/profile/orders',
}

export const profileSettings: Route = {
  title: 'Настройки',
  path: '/profile/settings',
  icon: SettingsIcon,
}

export const calendar: Route = {
  title: 'События',
  path: '/events',
  icon: EventsIcon,
}

export const login: Route = {
  title: 'Авторизация',
  path: '/login',
  icon: SettingsIcon,
}

export const registration: Route = {
  title: 'Регистрация',
  path: '/registration',
  icon: SettingsIcon,
}

export const passwordRecovery: Route = {
  title: 'Сброс пароля',
  path: '/password-recovery',
  icon: SettingsIcon,
}

export const passwordNew: Route = {
  title: 'Создание нового пароля',
  path: '/password-new',
  icon: SettingsIcon,
}

export const internalServerError: Route = {
  title: 'Что-то пошло не так!',
  path: '/500',
  icon: () => null,
  errorPage: true,
}

export const admin: Route = {
  title: 'Личный кабинет',
  path: '/admin',
  icon: () => null,
}

export const notFound: Route = {
  title: 'Страница не найдена!',
  path: '*',
  icon: () => null,
  errorPage: true,
}

export const widget: Route = {
  title: 'Виджет',
  path: '/widget',
  icon: HomeIcon,
}

export const privacy: Route = {
  title: 'Политика конфиденциальности',
  path: '/privacy',
}

export const terms: Route = {
  title: 'Пользовательское соглашение',
  path: '/terms',
}

export const contacts: Route = {
  title: 'Реквизиты юридического лица',
  path: '/contacts',
}
