import React from 'react'
import { Helmet } from 'react-helmet-async'

import ButtonIconLink from 'shared/components/ButtonIconLink'
import Header from 'shared/core/components/Header'
import Typography from 'shared/components/Typography'
import { ReactComponent as HomeIcon } from 'shared/assets/icons/home.svg'
import { ReactComponent as SoccerField } from 'shared/assets/icons/soccer_field_border.svg'
import * as routes from 'shared/constants/routes'

import styles from './ErrorPage.module.css'

interface ErrorPageProps {
  code: number
  text: string
}

const ErrorPage: React.FC<ErrorPageProps> = ({ code, text }): React.ReactElement => {
  return (
    <>
      <Helmet>
        <title>{`${code} - ${text}`}</title>
      </Helmet>
      <div>
        <Header />
        <div className={styles.errorPage}>
          <div className={styles.info}>
            <SoccerField />
            <Typography className={styles.info__code}>{code}</Typography>
          </div>

          <div className={styles.content}>
            <Typography className={styles.content__text}>{text}</Typography>

            <ButtonIconLink to={routes.home.path} color="secondary" label="На главную">
              <HomeIcon />
            </ButtonIconLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default ErrorPage
