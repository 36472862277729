import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { PaginationParams, Theme } from 'shared/types'
import { DEFAULT_PAGINATION } from 'shared/constants'

export type CoreState = {
  theme: Theme
  searchField?: string
  pagination: PaginationParams
}

const initialState: CoreState = {
  theme: Theme.light,
  searchField: undefined,
  pagination: { ...DEFAULT_PAGINATION, size: 30 },
}

const core = createSlice({
  name: '@@core',
  initialState,
  reducers: {
    setTheme: (state: Draft<CoreState>, action: PayloadAction<Theme>): void => {
      state.theme = action.payload
    },
    setSearchField: (state: Draft<CoreState>, action: PayloadAction<string>): void => {
      state.searchField = action.payload
    },
  },
})

export const { reducer } = core
