import { ErrorNotice } from 'shared/features/Notification/types'
import { getErrorNotice, isAxiosError } from 'shared/features/Notification/utils'

import { CreateOrderError } from '../constants'

export const createOrderErrorHandler = (e: unknown): ErrorNotice => {
  let message = CreateOrderError.ServerUnvailable
  if (isAxiosError(e)) {
    if (e.response?.status === 412) {
      message = CreateOrderError.ChannelUnvailable
    }
  }

  return getErrorNotice(message)
}
