import Cookies from 'universal-cookie'

import { Platform, PLATFORM } from 'shared/constants'

class ProfileService {
  private readonly cookies: Cookies = new Cookies()

  public get platform(): Platform | null {
    return this.cookies.get(PLATFORM) || Platform.PC
  }

  public set platform(value: Platform | null) {
    this.cookies.set(PLATFORM, value || Platform.PC, { path: '/' })
  }
}

export default new ProfileService()
