import React, { useMemo, memo } from 'react'
import cn from 'clsx'

import { ReactComponent as LogoIcon } from 'shared/assets/icons/logo.svg'
import YandexAds from 'shared/ads/YandexAds'
import WidgetAds from 'shared/ads/WidgetAds'

import styles from './Banner.module.css'

type BannerProps = {
  width?: number
  height: number
  blockId?: string
  renderTo?: string
  className?: string
  dark?: boolean
  style?: React.CSSProperties
  isWidget?: boolean
}

const Banner: React.FC<BannerProps> = ({
  width,
  height,
  blockId,
  renderTo,
  className,
  dark = false,
  style: styleProp,
  isWidget = false,
}): React.ReactElement => {
  const style: React.CSSProperties = useMemo(
    (): React.CSSProperties => ({
      width,
      height,
      ...styleProp,
    }),
    [width, height, styleProp],
  )

  const size: string = useMemo((): string => `${width} x ${height}`, [width, height])

  const emptyBanner = !(blockId && renderTo) && (
    <div className={styles.info}>
      <LogoIcon className={styles.info__logo} />

      <div className={styles.info__text}>
        <div>
          <strong>Баннер</strong>
        </div>
        <div>{size}</div>
      </div>
    </div>
  )

  const bannerContent =
    blockId &&
    renderTo &&
    (isWidget ? (
      <WidgetAds blockId={blockId} renderTo={renderTo} />
    ) : (
      <YandexAds blockId={blockId} renderTo={renderTo} />
    ))

  return (
    <div
      className={cn(
        styles.banner,
        {
          [styles.banner_dark]: dark,
        },
        className,
      )}
      style={style}
    >
      {emptyBanner}

      {bannerContent}
    </div>
  )
}

export default memo(Banner)
