import consoleError from 'shared/utils/consoleError'

import { BuyPlatform } from '../Buy/constants'

import { SendMethod } from './constants'
import {
  SendMessage,
  SendMessageeBody,
  StartMessageParams,
  BeginMessageParams,
  SendMessageParams,
  ResponseMessage,
  ResponseMessageParams,
  ResponseMessageResult,
  ResponseBodyMessages,
} from './types'

export class WebSocketMessageService {
  private static _messageId = 0

  private static _jsonrpc = '2.0'

  public static get currentMessageId() {
    return WebSocketMessageService._messageId
  }

  public static stringifyMessage(data: SendMessage<SendMessageParams>): string | null {
    try {
      return JSON.stringify(data)
    } catch (error) {
      consoleError(error)
    }
    return null
  }

  private static mapToSendMessage<T extends SendMessageParams>(data: SendMessageeBody<T>): SendMessage<T> {
    WebSocketMessageService.increaseId()
    return {
      id: WebSocketMessageService._messageId,
      jsonrpc: WebSocketMessageService._jsonrpc,
      ...data,
    }
  }

  public static getMessageWithoutParams(method: SendMethod) {
    return WebSocketMessageService.mapToSendMessage<null>({
      method,
      params: null,
    })
  }

  public static getOrderMessage() {
    return WebSocketMessageService.getMessageWithoutParams(SendMethod.order)
  }

  public static getStopMessage() {
    return WebSocketMessageService.getMessageWithoutParams(SendMethod.cmdStopDelivery)
  }

  public static getNextStepMessage() {
    return WebSocketMessageService.getMessageWithoutParams(SendMethod.nextStep)
  }

  public static getClearLotsMessage() {
    return WebSocketMessageService.getMessageWithoutParams(SendMethod.clearLots)
  }

  public static getCancelMessage() {
    return WebSocketMessageService.getMessageWithoutParams(SendMethod.cancel)
  }

  public static getFindAnotherPlayerMessage() {
    return WebSocketMessageService.getMessageWithoutParams(SendMethod.findAnotherPlayer)
  }

  public static getStartMessage(platform: BuyPlatform, amount: number) {
    return WebSocketMessageService.mapToSendMessage<StartMessageParams>({
      method: SendMethod.cmdStartDelivery,
      params: {
        amount,
        platform,
      },
    })
  }

  public static getBeginMessage(initialBalance: number) {
    return WebSocketMessageService.mapToSendMessage<BeginMessageParams>({
      method: SendMethod.begin,
      params: [
        {
          initial_balance: initialBalance,
        },
      ],
    })
  }

  public static getSearchPlayerMessage(query: string) {
    return WebSocketMessageService.mapToSendMessage<string[]>({
      method: SendMethod.searchPlayer,
      params: [query],
    })
  }

  public static getSearchAuctionsMessage(id: number) {
    return WebSocketMessageService.mapToSendMessage<number[]>({
      method: SendMethod.searchAuctions,
      params: [id],
    })
  }

  public static getSelectTradeMessage(id: string) {
    return WebSocketMessageService.mapToSendMessage<string[]>({
      method: SendMethod.selectTrade,
      params: [id],
    })
  }

  public static parseMessage<T extends ResponseBodyMessages>(data: string): ResponseMessage<T> | null {
    try {
      const message: ResponseMessage<T> = JSON.parse(data)
      return message
    } catch (error) {
      consoleError(error)
    }

    return null
  }

  public static isResultMessage(
    message: ResponseMessage<ResponseBodyMessages>,
  ): message is ResponseMessage<ResponseMessageResult> {
    return 'result' in message
  }

  public static parseMessageWithParams<T extends ResponseMessageParams>(data: string) {
    return WebSocketMessageService.parseMessage<T>(data)
  }

  public static parseMessageWithResult<T extends ResponseMessageResult>(data: string) {
    return WebSocketMessageService.parseMessage<T>(data)
  }

  private static increaseId(): void {
    WebSocketMessageService._messageId += 1
  }

  public static resetMessageId(): void {
    WebSocketMessageService._messageId = 0
  }
}
