import React from 'react'

import TypographyLink from 'shared/components/TypographyLink'
import Typography from 'shared/components/Typography'
import useScroll from 'shared/features/Home/hooks/useScroll'

import styles from './Header.module.css'

export const HeaderNav = () => {
  const { howToClickHandle } = useScroll()

  return (
    <nav className={styles.nav}>
      <TypographyLink to="/" className={styles.nav__item} color="secondary" size="xs" uppercase>
        Главная
      </TypographyLink>
      <Typography onClick={howToClickHandle} className={styles.nav__item} color="secondary" size="xs" uppercase>
        Как купить?
      </Typography>
      <TypographyLink to="/reviews" className={styles.nav__item} color="secondary" size="xs" uppercase>
        Отзывы
      </TypographyLink>
    </nav>
  )
}
