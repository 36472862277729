import { CombinedState, combineReducers, Reducer } from '@reduxjs/toolkit'
import { connectRouter, RouterState } from 'connected-react-router'
import { History } from 'history'

import { reducer as moderator, ModeratorState } from 'shared/features/Moderator/ducks'
import { reducer as profile, ProfileState } from 'shared/features/Profile/ducks'
import { reducer as core, CoreState } from 'shared/core/ducks'
import { reducer as webSocket, WebSocketState } from 'shared/features/WebSocket/ducks'
import { reducer as buy, BuyState } from 'shared/features/Buy/ducks'
import { reducer as reviews, ReviewsState } from 'shared/features/Reviews/ducks'
import { reducer as home, HomeState } from 'shared/features/Home/ducks'
import { reducer as auth, AuthState } from 'shared/features/Auth/ducks'
import { reducer as notification, NotificationState } from 'shared/features/Notification/ducks'

export type AppState = {
  router: RouterState
  core: CoreState
  webSocket: WebSocketState
  profile: ProfileState
  moderator: ModeratorState
  buy: BuyState
  reviews: ReviewsState
  home: HomeState
  auth: AuthState
  notification: NotificationState
}

export default (history: History): Reducer<CombinedState<AppState>> =>
  combineReducers<AppState>({
    router: connectRouter(history),
    core,
    webSocket,
    profile,
    moderator,
    buy,
    reviews,
    home,
    auth,
    notification,
  })
