import React from 'react'

import { NoticeType } from 'shared/features/Notification/constants'
import { ReactComponent as error } from 'shared/assets/icons/error.svg'
import { ReactComponent as success } from 'shared/assets/icons/success.svg'

const icons: Record<NoticeType, React.FC<React.SVGProps<SVGSVGElement>>> = {
  [NoticeType.Success]: success,
  [NoticeType.Warning]: success,
  [NoticeType.Error]: error,
}

export const getIcon = (type: NoticeType) => {
  return icons[type]
}
