import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import { routerMiddleware } from 'connected-react-router'

import { reducer as rootReducer } from 'shared/store'
import history from 'client/history'
import rootSaga from 'client/sagas'
import getInitialState from 'client/utils/getInitialState'

const logger = createLogger({
  collapsed: true,
})

const defaultMiddleware = getDefaultMiddleware({
  thunk: false,
})

const sagaMiddleware = createSagaMiddleware()
const middleware = [...defaultMiddleware, sagaMiddleware, routerMiddleware(history)]

if (__DEV__) {
  middleware.push(logger)
}

const store = configureStore({
  reducer: rootReducer(history),
  middleware,
  preloadedState: getInitialState(),
})

if (__DEV__ && module.hot) {
  module.hot.accept('shared/store/reducer', () => {
    // eslint-disable-next-line global-require
    const nextReducer = require('shared/store/reducer').default(history)

    store.replaceReducer(nextReducer)
  })
}

export default store

sagaMiddleware.run(rootSaga)
