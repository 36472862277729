import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

import setRef from 'shared/utils/setRef'
import useForkRef from 'shared/hooks/useForkRef'

export interface PortalProps {
  children: React.ReactElement
  disablePortal?: boolean
  onRendered?: () => void
}

const Portal = React.forwardRef<HTMLElement, PortalProps>(function Portal(props, ref) {
  const { children, disablePortal = false, onRendered } = props
  const [mountNode, setMountNode] = React.useState<HTMLElement | null>(null)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleRef = useForkRef(React.isValidElement(children) ? children.ref : null, ref)

  useEffect(() => {
    if (!disablePortal) {
      setMountNode(document.body)
    }
  }, [disablePortal])

  useEffect(() => {
    if (mountNode && !disablePortal) {
      setRef(ref, mountNode)
      return () => {
        setRef(ref, null)
      }
    }

    return undefined
  }, [ref, mountNode, disablePortal])

  useEffect(() => {
    if (onRendered && (mountNode || disablePortal)) {
      onRendered()
    }
  }, [onRendered, mountNode, disablePortal])

  if (disablePortal) {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, {
        ref: handleRef,
      })
    }
    return children
  }

  return mountNode ? ReactDOM.createPortal(children, mountNode) : mountNode
})

export default Portal
