import { SelectOption } from 'shared/components/interfaces'
import { ReactComponent as ps4Icon } from 'shared/assets/icons/platforms/ps4.svg'
import { ReactComponent as xboxIcon } from 'shared/assets/icons/platforms/xbox.svg'
import { ReactComponent as pcIcon } from 'shared/assets/icons/platforms/pc-mac.svg'

import { GetRequestParams, PaginationParams } from '../types'

const DEFAULT_GET_PARAMS: GetRequestParams = {
  page: 0,
  size: 20,
}

export const ACCESS_TOKEN = 'access_token'
export const REFRESH_TOKEN = 'refresh_token'
export const PLATFORM = 'platform'

export const DEFAULT_PAGINATION: PaginationParams = {
  size: DEFAULT_GET_PARAMS.size,
  number: 0,
  totalElements: 0,
  totalPages: 0,
}

export enum Align {
  left = 'left',
  center = 'center',
  right = 'right',
  justify = 'justify',
  inherit = 'inherit',
}

export const ChemistryDefault = 'default'
export const ChemistryReset = 'Сбросить'

export enum Platform {
  PC = 'PC',
  PS4 = 'PS4',
  XBOX = 'XBOX',
}

export const platforms: SelectOption<Platform>[] = [
  {
    value: Platform.PC,
    label: 'PC',
    icon: pcIcon,
  },
  {
    value: Platform.PS4,
    label: 'PS',
    icon: ps4Icon,
  },
  {
    value: Platform.XBOX,
    label: 'XBOX',
    icon: xboxIcon,
  },
]

export const dayNames: Array<string> = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']

export enum Permissions {
  ALL = 'ALLOWED_FOR_ALL',
  DELETE = 'DELETE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  WRITE = 'WRITE',
  WRITE_LIKE_DISLIKE = 'WRITE_LIKE_DISLIKE',

  ADMIN = 'ROLE_ADMIN',
  COACH = 'ROLE_COACH',
  MODERATOR = 'ROLE_MODERATOR',
  USER = 'ROLE_USER',
  TRUSTED_USER = 'ROLE_TRUSTED_USER',
}

export const REDIRECT_SEARCH_KEY = 'redirectPath'
