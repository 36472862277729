import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

import ButtonIcon, { ButtonIconProps } from 'shared/components/ButtonIcon'

type ButtonIconLinkPropsType = ButtonIconProps & LinkProps
export interface ButtonIconLinkProps extends ButtonIconLinkPropsType {}

const ButtonIconLink: React.FC<ButtonIconLinkProps> = (props): React.ReactElement => (
  <ButtonIcon {...props} component={Link} />
)

export default ButtonIconLink
