import { createSelector } from '@reduxjs/toolkit'

import { AppState } from 'shared/store/reducer'
import { PaginationParams, User } from 'shared/types'
import { Permissions, Platform } from 'shared/constants'
import AuthService from 'shared/features/Auth/AuthService'
import { Total, OrdersFilter, OrdersListProps } from 'shared/features/Profile/typings'
import { CreateOrderResponse, FtpPayment, MoneyPayment } from 'shared/features/Buy/types'

export const getUser = (state: AppState): User | undefined => state.profile.user
export const getProfileErrors = (state: AppState): string | undefined => state.profile.errors
export const getProfileStatus = (state: AppState): string | undefined => state.profile.status
export const getPlatform = (state: AppState): Platform => state.profile.platform
export const getOrdersFilter = (state: AppState): Partial<OrdersFilter> => state.profile.ordersFilter
export const getOrdersPagination = (state: AppState): PaginationParams => state.profile.ordersPagination
export const getOrders = (state: AppState): CreateOrderResponse[] => state.profile.orders
export const getOrdersIsLoading = (state: AppState): boolean => state.profile.ordersIsLoading
export const getTotal = (state: AppState): Total | null => state.profile.total

export const getAuthorized = createSelector<AppState, User | undefined, boolean>(
  getUser,
  (user: User | undefined): boolean => !!user && AuthService.hasToken(),
)

export const getTotalPoints = createSelector(getUser, user => user?.totalPoints || 0)

export const getUserEmail = createSelector(getUser, user => user?.email || '')

export const getPermissions = createSelector<AppState, User | undefined, boolean, Array<Permissions>>(
  getUser,
  getAuthorized,
  (user: User | undefined, isAuthorized: boolean): Array<Permissions> => {
    if (isAuthorized && user) {
      return user.permissions
    }

    return []
  },
)

const mapPaymentsToValues = (
  moneyPayment: MoneyPayment | null,
  ftpPayment: FtpPayment | null,
): { ftp: number; money: string } => {
  const { orderSum: money } = moneyPayment || { orderSum: '' }

  const { amountValueFtp: ftp } = ftpPayment || { amountValueFtp: 0 }

  return {
    ftp,
    money,
  }
}

export const getOrdersList = createSelector(
  getOrders,
  (orders: CreateOrderResponse[]): OrdersListProps =>
    orders.reduce<OrdersListProps>((acc, { order, ftpPayment, moneyPayment }) => {
      if (order) {
        const { amountBought, date, ftpRefund, uuid, platform, description } = order
        acc.push({
          amountBought,
          date,
          ftpRefund,
          uuid,
          platform,
          description,
          ...mapPaymentsToValues(moneyPayment, ftpPayment),
        })
      }
      return acc
    }, []),
)
