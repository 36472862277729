import qs from 'query-string'

import axios from 'shared/utils/axios'
import { Platform } from 'shared/constants'
import { Pageable, User } from 'shared/types'

import { CreateOrderResponse } from '../Buy/types'

import {
  GetRequestParamsOrders,
  Total,
  UpdateEmailAction,
  UpdateNameSurnameAction,
  UpdatePasswordAction,
} from './typings'

export default class ProfileRepository {
  private static readonly authEndpoint = `${AUTH_URL}/api/security`

  private static readonly coreEndpoint = FUTOPEDIA_CORE

  private static readonly serverUrl = SERVER_URL

  public static getAccount = async (): Promise<User> => {
    const { data } = await axios.get<User>(`${ProfileRepository.authEndpoint}/me`)

    return data
  }

  public static updateEmail = async (email: UpdateEmailAction): Promise<User> => {
    const { data } = await axios.post<User>(`${ProfileRepository.coreEndpoint}/personal/account/update`, email)

    return data
  }

  public static updatePassword = async (password: UpdatePasswordAction): Promise<User> => {
    const { data } = await axios.post<User>(
      `${ProfileRepository.coreEndpoint}/personal/account/update/password`,
      password,
    )

    return data
  }

  public static updateNameSurname = async (name: UpdateNameSurnameAction): Promise<User> => {
    const { data } = await axios.post<User>(`${ProfileRepository.coreEndpoint}/personal/account/update/name`, name)

    return data
  }

  public static updateAvatar = async (file: FormData): Promise<void> => {
    await axios.post<void>(`${ProfileRepository.coreEndpoint}/personal/photo`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  public static updatePlatform = async (platform: Platform): Promise<Platform> => {
    const { data } = await axios.patch<User>(`${ProfileRepository.coreEndpoint}/personal/account/update/platform`, {
      platform,
    })

    return data.platform
  }

  public static linkSocialAccount = async (linkData: FormData): Promise<void> => {
    const { data } = await axios.post<void>(`${ProfileRepository.authEndpoint}/merge`, linkData)
    return data
  }

  public static unlinkSocialAccount = async (linkData: FormData): Promise<void> => {
    const { data } = await axios.post<void>(`${ProfileRepository.authEndpoint}/unlink`, linkData)
    return data
  }

  public static getFutopediaUserId = async (authData: FormData): Promise<{ id: string; email: string }> => {
    const { data } = await axios.post<{ id: string; email: string }>(
      `${ProfileRepository.authEndpoint}/userId`,
      authData,
    )
    return data
  }

  public static checkLoginUniq = async (login: string): Promise<boolean> => {
    const response = await axios.get<void>(`${ProfileRepository.authEndpoint}/checkLogin?login=${login}`)
    return response.status === 200
  }

  public static getOrders = async (params: GetRequestParamsOrders) => {
    const { data } = await axios.get<Pageable<CreateOrderResponse>>(
      `${ProfileRepository.serverUrl}/personal/profile/orders?${qs.stringify(params)}`,
    )

    return data
  }

  public static getTotal = async (): Promise<Total> => {
    const { data } = await axios.get<Total>(`${ProfileRepository.serverUrl}/personal/profile/spentmoney`)

    return data
  }
}
