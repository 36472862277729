export default (): number => {
  const scrollDiv = document.createElement('div')

  requestAnimationFrame(() => {
    scrollDiv.style.width = '99px'
    scrollDiv.style.height = '99px'
    scrollDiv.style.position = 'absolute'
    scrollDiv.style.top = '-9999px'
    scrollDiv.style.overflow = 'scroll'
    document.body.appendChild(scrollDiv)
  })

  const scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth

  requestAnimationFrame(() => {
    document.body.removeChild(scrollDiv)
  })

  return scrollbarSize
}
