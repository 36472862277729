import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit'

import { ErrorNotificationPayload } from 'shared/features/Notification/types'
import { MessageMethod } from 'shared/features/WebSocket/constants'

import { BuyPlatform, StepName, steps } from '../constants'
import {
  BuyInfo,
  CancelOrderRequest,
  CreateOrderRequest,
  ExchangeRatesResponse,
  Step,
  PaymentRequest,
  PaymentResponse,
  Order,
} from '../types'

export type BuyState = {
  amount: number
  initialBalance: number
  platform: BuyPlatform
  exchangeRates: ExchangeRatesResponse | null
  exchangeRatesIsLoading: boolean
  buyInfo: BuyInfo | null
  messageMethod: MessageMethod | null
  lastOrder: Order | null
  lastOrderError: boolean
  lastOrderIsLoading: boolean
  createOrderIsLoading: boolean
  cancelOrderIsLoading: boolean
  isPreloadInfoCompleted: boolean
  payment: PaymentResponse | null
  paymentUrl: string | null
  paymentIsLoading: boolean
  steps: Step[]
  activeStep: StepName
  isStartSelling: boolean
}

const initialState: BuyState = {
  amount: MIN_COINS_AMOUNT,
  initialBalance: MIN_INITIAL_BALANCE,
  platform: BuyPlatform.Console,
  exchangeRates: null,
  exchangeRatesIsLoading: false,
  buyInfo: null,
  messageMethod: null,
  lastOrder: null,
  lastOrderError: false,
  lastOrderIsLoading: false,
  isPreloadInfoCompleted: false,
  createOrderIsLoading: false,
  cancelOrderIsLoading: false,
  payment: null,
  paymentUrl: null,
  paymentIsLoading: false,
  steps: [...steps],
  activeStep: StepName.Payment,
  isStartSelling: false,
}

const buy = createSlice({
  name: '@@buy',
  initialState,
  reducers: {
    setAmount: (state: Draft<BuyState>, action: PayloadAction<number>): void => {
      state.amount = action.payload
    },
    setInitialBalance: (state: Draft<BuyState>, action: PayloadAction<number>): void => {
      state.initialBalance = action.payload
    },
    setPlatform: (state: Draft<BuyState>, action: PayloadAction<BuyPlatform>): void => {
      state.platform = action.payload
      state.amount = MIN_COINS_AMOUNT
    },
    createOrder: (state: Draft<BuyState>, _action: PayloadAction<CreateOrderRequest>): void => {
      state.createOrderIsLoading = true
    },
    createOrderSuccess: (state: Draft<BuyState>): void => {
      state.createOrderIsLoading = false
    },
    createOrderErrorNotification: (state: Draft<BuyState>, _action: ErrorNotificationPayload): void => {
      state.createOrderIsLoading = false
    },
    cancelOrder: (state: Draft<BuyState>, _action: PayloadAction<CancelOrderRequest>): void => {
      state.cancelOrderIsLoading = true
    },
    cancelOrderSuccess: (state: Draft<BuyState>): void => {
      state.cancelOrderIsLoading = false
    },
    cancelOrderError: (state: Draft<BuyState>): void => {
      state.cancelOrderIsLoading = false
    },
    getLastOrder: (state: Draft<BuyState>): void => {
      state.lastOrderError = false
      state.lastOrderIsLoading = true
    },
    getLastOrderSuccess: (state: Draft<BuyState>, action: PayloadAction<Order | null>): void => {
      state.lastOrder = action.payload
      state.lastOrderIsLoading = false
    },
    getLastOrderError: (state: Draft<BuyState>): void => {
      state.lastOrderIsLoading = false
      state.lastOrderError = true
    },
    preloadInfo: (state: Draft<BuyState>): void => {
      state.isPreloadInfoCompleted = false
    },
    preloadInfoSuccess: (state: Draft<BuyState>): void => {
      state.isPreloadInfoCompleted = true
    },
    preloadInfoError: (state: Draft<BuyState>): void => {
      state.isPreloadInfoCompleted = true
    },
    setIsPreloadInfoCompleted: (state: Draft<BuyState>, action: PayloadAction<boolean>): void => {
      state.isPreloadInfoCompleted = action.payload
    },
    getExchangeRates: (state: Draft<BuyState>): void => {
      state.exchangeRatesIsLoading = true
    },
    getExchangeRatesSuccess: (state: Draft<BuyState>, action: PayloadAction<ExchangeRatesResponse>): void => {
      state.exchangeRates = action.payload
      state.exchangeRatesIsLoading = false
    },
    getExchangeRatesError: (state: Draft<BuyState>): void => {
      state.exchangeRatesIsLoading = false
    },
    handlePayment: (state: Draft<BuyState>, _action: PayloadAction<PaymentRequest>): void => {
      state.paymentIsLoading = true
    },
    setPaymentUrl: (state: Draft<BuyState>, action: PayloadAction<string | null>) => {
      state.paymentUrl = action.payload
    },
    actualizePayment: (state: Draft<BuyState>, _action: PayloadAction<string>): void => {
      state.paymentIsLoading = true
    },
    cancelPayment: (state: Draft<BuyState>, _action: PayloadAction<string>): void => {
      state.paymentIsLoading = true
    },
    createPaymentSuccess: (state: Draft<BuyState>, action: PayloadAction<PaymentResponse>): void => {
      state.payment = action.payload
      state.paymentIsLoading = false
    },
    createPaymentErrorNotification: (state: Draft<BuyState>, _action: ErrorNotificationPayload): void => {
      state.paymentIsLoading = false
    },
    setBuyInfo: (state: Draft<BuyState>, action: PayloadAction<BuyInfo | null>): void => {
      state.buyInfo = action.payload
    },
    setMessageMethod: (state: Draft<BuyState>, action: PayloadAction<MessageMethod>): void => {
      state.messageMethod = action.payload
    },
    setActiveStep: (state: Draft<BuyState>, action: PayloadAction<StepName>): void => {
      const activeStepIndex = state.steps.findIndex(({ name }) => name === action.payload)
      state.steps = state.steps.map((step, i) => {
        if (i < activeStepIndex) {
          step.isViewed = true
          return step
        }

        step.isViewed = false
        return step
      })

      state.activeStep = action.payload
    },
    setIsStartSelling: (state: Draft<BuyState>, action: PayloadAction<boolean>): void => {
      state.isStartSelling = action.payload
    },
    reset: (state: Draft<BuyState>): void => {
      state.buyInfo = null
      state.lastOrder = null
      state.payment = null
      state.paymentUrl = null
      state.messageMethod = null
      state.amount = MIN_COINS_AMOUNT
      state.platform = BuyPlatform.Console
      state.activeStep = StepName.Payment
      state.steps = [...steps]
    },
  },
})

export const { actions, reducer } = buy
