import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Location } from 'history'

import { home as homeRoute } from 'shared/constants/routes'

import { ScrollTo } from '../constants'
import { actions } from '../ducks'
import { getScrollTo } from '../ducks/selectors'

type ScrollToHook = {
  howToClickHandle: () => void
}

export default (): ScrollToHook => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location: Location = useLocation()

  const scrollTo = useSelector(getScrollTo)

  useEffect(() => {
    const howToBlock = document.getElementById(ScrollTo.howToBlock)
    if (!howToBlock || !scrollTo) {
      return
    }
    setTimeout(() => {
      howToBlock.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, 0)
    dispatch(actions.setScrollTo(null))
  }, [location, scrollTo])

  const howToClickHandle = () => {
    dispatch(actions.setScrollTo(ScrollTo.howToBlock))
    if (location.pathname !== homeRoute.path) {
      history.push(homeRoute.path)
    }
  }

  return { howToClickHandle }
}
