import { createSlice } from '@reduxjs/toolkit'

import { DEFAULT_ERROR_NOTICE } from '../constants'
import { ErrorNotificationPayload, Notice, NotificationPayload } from '../types'
import { isNotificationAction } from '../utils'

export type NotificationState = {
  notification: Notice | null
}

const initialState: NotificationState = {
  notification: null,
}

const notification = createSlice({
  name: '@@notification',
  initialState,
  reducers: {
    setNotification: (state, action: NotificationPayload) => {
      state.notification = action.payload
    },
    errorNotify: (state, action: ErrorNotificationPayload) => {
      state.notification = action.payload || DEFAULT_ERROR_NOTICE
    },
  },
  extraReducers: builder => {
    builder.addMatcher(isNotificationAction, (state, action) => {
      state.notification = action.payload
    })
  },
})

export const { actions, reducer } = notification
