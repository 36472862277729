import { useMemo, Ref } from 'react'

import setRef from 'shared/utils/setRef'

export default function useForkRef<T>(refA: Ref<T>, refB: Ref<T>): Ref<T> {
  return useMemo(() => {
    if (refA == null && refB == null) {
      return null
    }
    return refValue => {
      setRef(refA, refValue)
      setRef(refB, refValue)
    }
  }, [refA, refB])
}
