import robotoLightTtf from 'shared/assets/fonts/roboto/Roboto-Light.ttf'
import robotoLightItalicTtf from 'shared/assets/fonts/roboto/Roboto-LightItalic.ttf'
import robotoRegularTtf from 'shared/assets/fonts/roboto/Roboto-Regular.ttf'
import robotoItalicTtf from 'shared/assets/fonts/roboto/Roboto-Italic.ttf'
import robotoMediumTtf from 'shared/assets/fonts/roboto/Roboto-Medium.ttf'
import robotoMediumItalicTtf from 'shared/assets/fonts/roboto/Roboto-MediumItalic.ttf'
import robotoBoldTtf from 'shared/assets/fonts/roboto/Roboto-Bold.ttf'
import robotoBoldItalicTtf from 'shared/assets/fonts/roboto/Roboto-BoldItalic.ttf'

export default [
  robotoLightTtf,
  robotoLightItalicTtf,
  robotoRegularTtf,
  robotoItalicTtf,
  robotoMediumTtf,
  robotoMediumItalicTtf,
  robotoBoldTtf,
  robotoBoldItalicTtf,
]
