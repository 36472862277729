import React from 'react'

import ScriptLoader from 'shared/components/ScriptLoader'

export type YandexAdsConfig = {
  blockId: string
  renderTo: string
}

const YandexAds: React.FC<YandexAdsConfig> = ({ children, blockId, renderTo }) => {
  const onLoad = React.useCallback(() => {
    if (__CLIENT__) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.Ya.Context.AdvManager.render({
        blockId,
        renderTo,
        async: true,
      })
    }
  }, [])

  return (
    <ScriptLoader src="https://an.yandex.ru/system/context.js" onLoad={onLoad}>
      <div id={renderTo} />
      {children}
    </ScriptLoader>
  )
}

export default YandexAds
