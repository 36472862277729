export const ERRORS = {
  email_is_used: 'Данный адрес E-Mail уже используется!',
  wrong_password: 'Пароль введен неверно!',
  something_wrong: 'Что-то пошло не так!',
}

export const STATUSES = {
  email_update_success: 'E-mail изменен',
  password_update_success: 'Пароль изменен',
  avatar_update_success: 'Аватар изменен',
  name_update_success: 'Личные данные успешно добавлены!',
  cost_update_success: 'Ставка тренера успешно добавлена!',
}
