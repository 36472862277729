import React, { useMemo, forwardRef } from 'react'
import cn from 'clsx'

import capitalize from 'shared/utils/capitalize'

import styles from './Typography.module.css'
import { TypographyColor, TypographySize, TypographyWeight } from './constants'
import { TypographyProps } from './types'

const Typography = forwardRef<HTMLElement, TypographyProps>(function Typography(props, ref) {
  const {
    component: Component = 'div',
    className,
    children,
    color = TypographyColor.primary,
    size = TypographySize.s,
    weight = TypographyWeight.normal,
    spacy = false,
    ellipsis = false,
    italic = false,
    uppercase = false,
    ...rest
  } = props

  const classes: string = useMemo<string>(
    (): string =>
      cn(
        styles.typography,
        {
          [styles.ellipsis]: ellipsis,
          [styles.italic]: italic,
          [styles.uppercase]: uppercase,
          [styles.spacy]: spacy,
        },
        styles[`color${capitalize(color, true)}`],
        styles[`weight${capitalize(weight)}`],
        styles[`size${capitalize(size)}`],
        className,
      ),
    [color, ellipsis, className, size, weight, italic, uppercase, spacy],
  )

  return (
    <Component className={classes} ref={ref} {...rest}>
      {children}
    </Component>
  )
})

export default Typography
