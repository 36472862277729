import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

export interface WebSocketState {
  isOpenConnection: boolean
}

const initialState: WebSocketState = {
  isOpenConnection: false,
}

const webSocket = createSlice({
  name: '@@webSocket',
  initialState,
  reducers: {
    setIsOpenConnection: (state: Draft<WebSocketState>, action: PayloadAction<boolean>): void => {
      state.isOpenConnection = action.payload
    },
  },
})

export const { reducer, actions } = webSocket
