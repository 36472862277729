import eventEmitter from 'event-emitter'

import AuthService from '../Auth/AuthService'

import { MessageType, RECONNECTING_INTERVAL_MS, RECONNECTING_TRIES } from './constants'
import { ResponseMessageResult, ResponseWithResult, SendMessage, SendMessageParams } from './types'
import { WebSocketMessageService } from './WebSocketMessageService'

class WebSocketService {
  private static _ws: WebSocket

  private static _emitter = eventEmitter()

  private static _endpoint = `${WS_SERVER_URL}/delivery`

  private static _uuid: string

  private static _reconnectingTries = RECONNECTING_TRIES

  private static _reconnectingIntervalMs = RECONNECTING_INTERVAL_MS

  private static _reconnectingIntervalId: NodeJS.Timeout

  private static _shouldReconnect = true

  public static get emitter() {
    return WebSocketService._emitter
  }

  public static createConnection(uuid: string) {
    if (!uuid) {
      return
    }

    WebSocketService._uuid = uuid
    const ws = new WebSocket(`${WebSocketService._endpoint}/${WebSocketService._uuid}`, [
      'access_token',
      AuthService.getAccessToken() || '',
    ])
    WebSocketService._ws = ws

    ws.onopen = () => {
      WebSocketService._emitter.emit(MessageType.onopen, true)
      WebSocketService.reset()
    }

    ws.onmessage = ({ data }) => {
      if (!data) {
        return
      }

      const message = WebSocketMessageService.parseMessage(data)

      if (!message) {
        return
      }

      if (WebSocketMessageService.isResultMessage(message)) {
        const { id } = message
        WebSocketService._emitter.emit(id.toString(), message)
      } else {
        WebSocketService._emitter.emit(MessageType.params, message)
      }
    }

    ws.onclose = () => {
      WebSocketService._emitter.emit(MessageType.onopen, false)
      if (WebSocketService._shouldReconnect) {
        WebSocketService._reconnectingIntervalId = setInterval(() => {
          if (WebSocketService._reconnectingTries > 0) {
            WebSocketService.createConnection(WebSocketService._uuid)
            WebSocketService._reconnectingTries -= 1
          }
        }, WebSocketService._reconnectingIntervalMs)
      }
    }
  }

  public static send<T extends ResponseMessageResult, K extends SendMessageParams = SendMessageParams>(
    data: SendMessage<K>,
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const message = WebSocketMessageService.stringifyMessage(data)
      if (!message) {
        return
      }
      WebSocketService.waitForConnection(message)
      WebSocketService._emitter.on(data.id.toString(), (data: ResponseWithResult<T>) => {
        const { result, error } = data
        if (error) {
          reject(error)
        }

        resolve(result)
      })
    })
  }

  public static closeConnection() {
    WebSocketService._shouldReconnect = false
    WebSocketService._ws.close()
  }

  private static waitForConnection(message: string) {
    if (WebSocketService._ws.readyState === WebSocketService._ws.OPEN) {
      WebSocketService._ws.send(message)
    } else {
      setTimeout(() => {
        WebSocketService.waitForConnection(message)
      }, 100)
    }
  }

  private static reset() {
    WebSocketService._reconnectingTries = RECONNECTING_TRIES
    WebSocketService._shouldReconnect = true
    WebSocketMessageService.resetMessageId()
    clearInterval(WebSocketService._reconnectingIntervalId)
  }
}

export const { send, createConnection, closeConnection, emitter } = WebSocketService
