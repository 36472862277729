import { LotStatus } from '../constants'
import { Lot, Trade } from '../types'

export const getTradesByLotStatus = (lots: Lot[], lotStatus: LotStatus): Trade[] =>
  lots.reduce<Trade[]>((trades, { status, trade }) => {
    if (status === lotStatus) {
      trades.push(trade)
    }

    return trades
  }, [])
