import Cookies from 'universal-cookie'

import { LoginResponse } from 'shared/features/Auth/typings'
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'shared/constants'
import axios from 'shared/utils/axios'

export default class AuthService {
  private static _cookies: Cookies = new Cookies()

  static get cookies(): Cookies {
    return AuthService._cookies
  }

  static set cookies(value: Cookies) {
    AuthService._cookies = value
  }

  public static setToken = ({ access_token, refresh_token }: LoginResponse): void => {
    AuthService.removeToken()

    AuthService.cookies.set(ACCESS_TOKEN, access_token, { path: '/' })
    AuthService.cookies.set(REFRESH_TOKEN, refresh_token, { path: '/' })

    axios.defaults.headers.common.Authorization = `Bearer ${access_token}`
  }

  public static removeToken = (): void => {
    AuthService.cookies.remove(ACCESS_TOKEN, { path: '/' })
    AuthService.cookies.remove(REFRESH_TOKEN, { path: '/' })
    delete axios.defaults.headers.common.Authorization
  }

  public static getAccessToken = (): string | undefined => {
    return AuthService.cookies.get(ACCESS_TOKEN)
  }

  public static getRefreshToken = (): string | undefined => {
    return AuthService.cookies.get(REFRESH_TOKEN)
  }

  public static hasToken = (): boolean => {
    const access = AuthService.getAccessToken()
    const refresh = AuthService.getRefreshToken()

    return Boolean(access && refresh)
  }
}
