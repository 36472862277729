export enum TypographyColor {
  primary = 'primary',
  secondary = 'secondary',
  brand = 'brand',
  gray = 'gray',
  lightGray = 'lightGray',
  success = 'success',
  warning = 'warning',
  error = 'error',
  white = 'white',
}

export enum TypographyWeight {
  light = 'light',
  normal = 'normal',
  medium = 'medium',
  bold = 'bold',
}

export enum TypographySize {
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl',
  xxl = 'xxl',
  xxxl = 'xxxl',
  xxxxl = 'xxxxl',
  xxxxxl = 'xxxxxl',
}
