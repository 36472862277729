import React, { useEffect, useRef } from 'react'

type ScriptLoaderProps = {
  src?: string
  async?: boolean
  onLoad(): void
}

const ScriptLoader: React.FC<ScriptLoaderProps> = ({ async = true, children, src, onLoad }): React.ReactElement => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current?.lastChild) {
      if ((ref.current.lastChild as HTMLElement).getAttribute('src') === src) return

      const script = document.createElement('script')
      script.setAttribute('async', `${async}`)
      if (src) {
        script.setAttribute('src', src)
      }

      script.onload = onLoad

      ref.current.appendChild(script)
    }
  }, [async, src, onLoad])

  return <div ref={ref}>{children}</div>
}

export default ScriptLoader
