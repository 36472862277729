import React, { useEffect } from 'react'
import ReactGA from 'react-ga'

type GoogleAnalyticsProps = {
  id: string
}

const GoogleAnalytics: React.FC<GoogleAnalyticsProps> = ({ id }): null => {
  useEffect(() => {
    if (id) {
      ReactGA.initialize(id)
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }, [id])

  return null
}

export default GoogleAnalytics
