import React from 'react'
import cn from 'clsx'

import { ReactComponent as FutoPointIcon } from 'shared/assets/icons/futo-point.svg'
import Typography from 'shared/components/Typography'

import styles from './TotalPoints.module.css'

type Props = {
  totalPoints: number
  className?: string
}

export const TotalPoints: React.FC<Props> = ({ totalPoints, className }) => (
  <div className={cn(styles.root, className)}>
    <FutoPointIcon className={styles.icon} />
    <Typography color="white" weight="bold" size="xs">
      {totalPoints}
    </Typography>
  </div>
)
