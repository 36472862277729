import React, { forwardRef, useMemo, memo } from 'react'
import cn from 'clsx'

import { ButtonProps } from 'shared/components/Button'
import { OverrideProps } from 'shared/components/OverridableComponent'
import isDef from 'shared/utils/isDef'

import styles from './ButtonIcon.module.css'

enum ButtonIconColors {
  primary = 'primary',
  secondary = 'secondary',
  white = 'white',
  inherit = 'inherit',
  success = 'success',
}

enum ButtonVariants {
  text = 'text',
  contained = 'contained',
  outline = 'outline',
}

enum ButtonIconSize {
  small = 'small',
  normal = 'normal',
}

export enum ButtonIconOrientation {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

interface ButtonIconTypeMap<P = Record<string, unknown>, D extends React.ElementType = 'button'> {
  props: P &
    Pick<ButtonProps, 'onClick' | 'className' | 'fullWidth' | 'disabled'> & {
      component?: React.ElementType
      children: React.ReactElement
      color?: keyof typeof ButtonIconColors
      size?: keyof typeof ButtonIconSize
      variant?: keyof typeof ButtonVariants
      label?: string | number
      className?: string
      classNameLabel?: string
      classNameContent?: string
      classNameIcon?: string
      orientation?: keyof typeof ButtonIconOrientation
    }
  defaultComponent: D
}

export type ButtonIconProps<
  D extends React.ElementType = ButtonIconTypeMap['defaultComponent'],
  P = Record<string, unknown>
> = OverrideProps<ButtonIconTypeMap<P, D>, D>

const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(function ButtonIcon(props, ref): React.ReactElement {
  const {
    children,
    color = ButtonIconColors.inherit,
    size = ButtonIconSize.normal,
    orientation = ButtonIconOrientation.horizontal,
    variant = ButtonVariants.text,
    label,
    className,
    classNameContent,
    classNameIcon,
    classNameLabel,
    onClick,
    fullWidth,
    disabled = false,
    component: ComponentProp = 'button',
    type = 'button',
    ...otherProps
  } = props
  const classNamesRoot: string = useMemo(
    (): string =>
      cn(
        styles.buttonIcon,
        {
          [styles.buttonIcon_primary]: variant === ButtonVariants.text && color === ButtonIconColors.primary,
          [styles.buttonIcon_secondary]: variant === ButtonVariants.text && color === ButtonIconColors.secondary,
          [styles.buttonIcon_white]: variant === ButtonVariants.text && color === ButtonIconColors.white,
          [styles.buttonIcon_small]: variant === ButtonVariants.text && size === ButtonIconSize.small,
          [styles.buttonIcon__contained]: variant === ButtonVariants.contained,
          [styles.buttonIcon__contained_small]: variant === ButtonVariants.contained && size === ButtonIconSize.small,
          [styles.buttonIcon__contained_primary]:
            variant === ButtonVariants.contained && color === ButtonIconColors.primary,
          [styles.buttonIcon__contained_secondary]:
            variant === ButtonVariants.contained && color === ButtonIconColors.secondary,
          [styles.buttonIcon__contained_success]:
            variant === ButtonVariants.contained && color === ButtonIconColors.success,
          [styles.buttonIcon__outline]: variant === ButtonVariants.outline,
          [styles.buttonIcon__outline_small]: variant === ButtonVariants.outline && size === ButtonIconSize.small,
          [styles.buttonIcon__outline_primary]:
            variant === ButtonVariants.outline && color === ButtonIconColors.primary,
          [styles.buttonIcon__outline_secondary]:
            variant === ButtonVariants.outline && color === ButtonIconColors.secondary,
          [styles.buttonIcon__outline_success]:
            variant === ButtonVariants.outline && color === ButtonIconColors.success,
          [styles.buttonIcon_noLabel]: !label,
          [styles.buttonIcon_fullWidth]: fullWidth,
          [styles.buttonIcon_disabled]: disabled,
        },
        className,
      ),
    [className, color, fullWidth, label, size, disabled, variant],
  )

  const classNamesContent: string = useMemo(
    (): string =>
      cn(
        styles.buttonIcon__content,
        {
          [styles.buttonIcon__content_horizontal]: orientation === ButtonIconOrientation.horizontal,
          [styles.buttonIcon__content_vertical]: orientation === ButtonIconOrientation.vertical,
        },
        classNameContent,
      ),
    [classNameContent, orientation],
  )

  const classNamesLabel: string = useMemo(
    (): string =>
      cn(
        styles.buttonIcon__label,
        {
          [styles.buttonIcon_primary]: variant === ButtonVariants.text && color === ButtonIconColors.primary,
          [styles.buttonIcon_secondary]: variant === ButtonVariants.text && color === ButtonIconColors.secondary,
          [styles.buttonIcon_white]: variant === ButtonVariants.text && color === ButtonIconColors.white,
          [styles.buttonIcon__label_horizontal]: orientation === ButtonIconOrientation.horizontal,
          [styles.buttonIcon__label_vertical]: orientation === ButtonIconOrientation.vertical,
        },
        classNameLabel,
      ),
    [classNameLabel, color, orientation],
  )

  return (
    <ComponentProp
      type={type}
      className={classNamesRoot}
      onClick={onClick}
      ref={ref}
      disabled={disabled}
      {...otherProps}
    >
      <div className={classNamesContent}>
        <div className={cn(styles.buttonIcon__icon, classNameIcon)}>{children}</div>
        {isDef(label) && <div className={classNamesLabel}>{label}</div>}
      </div>
    </ComponentProp>
  )
})

export default memo(ButtonIcon)
