import qs from 'query-string'

import {
  CheckLoginEmailResponse,
  LoginGooglePayload,
  LoginPayload,
  LoginResponse,
  LoginTelegramPayload,
  LoginVKPayload,
  LoginYandexPayload,
  NewPasswordRequest,
  PlayersByBirthPayload,
  PlayersByBirthResponse,
  ReactivationPayload,
  RegistrationRequest,
  RegistrationResponse,
} from 'shared/features/Auth/typings'
import axios from 'shared/utils/axios'
import AuthService from 'shared/features/Auth/AuthService'
import { SocialNetwork } from 'shared/types'

const config = {
  grant_type: 'password',
  client_id: 'webapp',
  client_secret: 'webapp',
  scope: 'read write trust',
}

export default class AuthRepository {
  private static readonly _endpointCore = `${FUTOPEDIA_CORE}/api`

  private static readonly _endpointOAuth = `${AUTH_URL}/oauth`

  private static readonly _endpointSecurity = `${AUTH_URL}/api/security`

  private static readonly _endpointAuth = `${AuthRepository._endpointCore}/auth`

  private static readonly _endpointVKAuth = `${AUTH_URL}/api/security/vk/futtycoin`

  private static readonly _endpointGoogleAuth = `${AUTH_URL}/api/security/google`

  private static readonly _endpointYandexAuth = `${AUTH_URL}/api/security/yandex`

  private static readonly _endpointTelegramAuth = `${AUTH_URL}/api/security/telegram/futtycoin`

  public static get endpointCore(): string {
    return AuthRepository._endpointCore
  }

  public static get endpointOAuth(): string {
    return AuthRepository._endpointOAuth
  }

  public static get endpointVKAuth(): string {
    return AuthRepository._endpointVKAuth
  }

  public static get endpointGoogleAuth(): string {
    return AuthRepository._endpointGoogleAuth
  }

  public static get endpointYandexAuth(): string {
    return AuthRepository._endpointYandexAuth
  }

  public static get endpointTelegramAuth(): string {
    return AuthRepository._endpointTelegramAuth
  }

  public static get endpointSecurity(): string {
    return AuthRepository._endpointSecurity
  }

  public static get endpointAuth(): string {
    return AuthRepository._endpointAuth
  }

  public static get endpointGetToken(): string {
    return `${AuthRepository.endpointOAuth}/token`
  }

  public static get endpointGetTokenFromVK(): string {
    return `${AuthRepository.endpointVKAuth}/token`
  }

  public static get endpointGetTokenFromGoogle(): string {
    return `${AuthRepository.endpointGoogleAuth}/token`
  }

  public static get endpointGetTokenFromYandex(): string {
    return `${AuthRepository.endpointYandexAuth}/token`
  }

  public static get endpointGetTokenFromTelegram(): string {
    return `${AuthRepository.endpointTelegramAuth}/token`
  }

  private static socialNetworkEndpoint = (socialNetworkType: SocialNetwork) => {
    const socialNetworkList = {
      [SocialNetwork.VK]: AuthRepository.endpointGetTokenFromVK,
      [SocialNetwork.GOOGLE]: AuthRepository.endpointGetTokenFromGoogle,
      [SocialNetwork.TELEGRAM]: AuthRepository.endpointGetTokenFromTelegram,
      [SocialNetwork.YANDEX]: AuthRepository.endpointGetTokenFromYandex,
    }
    return socialNetworkList[socialNetworkType]
  }

  public static registration = async (value: RegistrationRequest): Promise<RegistrationResponse> => {
    const formData: FormData = new FormData()

    const jsonBlob = new Blob([JSON.stringify(value)], {
      type: 'application/json',
    })

    formData.append('userDto', jsonBlob)

    const { data } = await axios.post<RegistrationResponse>(`${AuthRepository.endpointCore}/registration`, formData)
    return data
  }

  public static activation = async (token: string): Promise<LoginResponse> => {
    const { data } = await axios.patch<LoginResponse>(`${AuthRepository.endpointAuth}/activation`, null, {
      headers: {
        'Activation-Token': token,
      },
    })

    return data
  }

  public static reactivation = async (payload: ReactivationPayload): Promise<void> => {
    await axios.get(`${AuthRepository.endpointCore}/users/tokens?${qs.stringify(payload)}`)
  }

  public static login = async (payload: LoginPayload): Promise<LoginResponse> => {
    const { data } = await axios.post<LoginResponse>(
      AuthRepository.endpointGetToken,
      qs.stringify({ ...config, ...payload }),
      {
        auth: {
          username: config.client_id,
          password: config.client_secret,
        },
      },
    )
    return data
  }

  public static loginWithSocialNetWork = async (
    payload: LoginVKPayload | LoginTelegramPayload | LoginGooglePayload | LoginYandexPayload,
    socialNetworkType: SocialNetwork,
  ): Promise<LoginResponse> => {
    const { data } = await axios.post<LoginResponse>(
      AuthRepository.socialNetworkEndpoint(socialNetworkType),
      qs.stringify({ ...config, ...payload }),
    )
    return data
  }

  public static refreshToken = async (): Promise<LoginResponse> => {
    const { data } = await axios.post<LoginResponse>(
      AuthRepository.endpointGetToken,
      qs.stringify({
        grant_type: 'refresh_token',
        refresh_token: AuthService.getRefreshToken(),
      }),
      {
        auth: {
          username: config.client_id,
          password: config.client_secret,
        },
      },
    )

    return data
  }

  public static logout = async (): Promise<void> => {
    await axios.get<void>(`${AuthRepository.endpointSecurity}/logout`)
  }

  public static resetPassword = async (email: string): Promise<void> => {
    delete axios.defaults.headers.common.Authorization

    await axios.post<void>(`${AuthRepository.endpointCore}/users/oblivion`, email, {
      headers: {
        'Content-Type': 'text/plain',
      },
    })
  }

  public static newPassword = async ({ password, token }: NewPasswordRequest): Promise<void> => {
    await axios.patch<void>(`${AuthRepository.endpointCore}/auth/oblivion/activation`, password, {
      headers: {
        'Activation-Token': token,
        'Content-Type': 'text/plain',
      },
    })
  }

  public static checkLogin = async (login: string): Promise<CheckLoginEmailResponse> => {
    const { data } = await axios.get<CheckLoginEmailResponse>(`${AuthRepository.endpointCore}/checkLogin`, {
      params: {
        login,
      },
    })
    return data
  }

  public static checkEmail = async (email: string): Promise<CheckLoginEmailResponse> => {
    const { data } = await axios.get<CheckLoginEmailResponse>(`${AuthRepository.endpointCore}/checkEmail`, {
      params: {
        email,
      },
    })
    return data
  }

  public static checkRecovery = async (email: string): Promise<CheckLoginEmailResponse> => {
    const { data } = await axios.get<CheckLoginEmailResponse>(`${AuthRepository.endpointCore}/checkRecovery`, {
      params: { email },
    })

    return data
  }

  public static getPlayersByBirth = async ({
    day,
    month,
  }: PlayersByBirthPayload): Promise<Array<PlayersByBirthResponse>> => {
    const { data } = await axios.get<Array<PlayersByBirthResponse>>(`${AuthRepository.endpointCore}/players/birthday`, {
      params: { day, month },
    })

    return data
  }
}
