export enum AuthTitle {
  Login = 'Авторизация',
  Registration = 'Регистрация',
  PasswordRecovery = 'Сброс пароля',
  NewPassword = 'Новый пароль',
}

export enum AuthSuccessMessage {
  Activation = 'Активация прошла успешно',
  Registration = 'Регистрация прошла успешно',
  Reactivation = 'Письмо повторно отправлено',
  NewPassword = 'Пароль успешно сохранен',
}

export enum AuthErrorMessage {
  Activation = 'Возникла ошибка при активации',
  Registration = 'Возникла ошибка при регистрации',
  Reactivation = 'Возникла ошибка при повторной отправке',
  CheckRecovery = 'Отправка нового письма для восстановления пароля будет недоступна',
  ResetPassword = 'Возникла ошибка при сбросе пароля',
  NewPassword = 'Возникла ошибка при отправке пароля',
}
