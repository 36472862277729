import { AnyAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { DefaultNoticeMessage, DEFAULT_ERROR_NOTICE, NoticeType } from './constants'
import { ErrorNotice, ErrorResponseWithDescription, NotificationPayload, SuccessNotice } from './types'

export const isAxiosError = <T>(err: unknown): err is AxiosError<T> => {
  return !!err && typeof err === 'object' && err !== null && 'isAxiosError' in err
}

const isError = (err: unknown): err is Error => {
  return !!err && err !== null && err instanceof Error
}

export const isNotificationAction = (action: AnyAction): action is NotificationPayload => {
  return action.type.endsWith('Notification')
}

export const getErrorNotice = (message: string, delay?: number): ErrorNotice => ({
  message,
  type: NoticeType.Error,
  delay,
})

export const getSuccessNotice = (message: string, delay?: number): SuccessNotice => ({
  message,
  type: NoticeType.Success,
  delay,
})

export const errorMessageHandler = (e: unknown): ErrorNotice => {
  if (isAxiosError<ErrorResponseWithDescription>(e)) {
    const message = e.response?.data.error_description || e.message || DefaultNoticeMessage.Error
    return getErrorNotice(message)
  }

  if (isError(e)) {
    return getErrorNotice(e.message)
  }

  return DEFAULT_ERROR_NOTICE
}
