/* eslint-disable @typescript-eslint/ban-ts-comment */
import qs from 'query-string'
import Axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios'
import { matchRoutes } from 'react-router-config'
import { push } from 'connected-react-router'

import AuthService from 'shared/features/Auth/AuthService'
import AuthRepository from 'shared/features/Auth/AuthRepository'
import { LoginResponse } from 'shared/features/Auth/typings'
import { login } from 'shared/constants/routes'
import { actions } from 'shared/features/Profile/ducks'
import routing from 'shared/routing'

type FailedQueue = Array<{ resolve(value?: unknown): void; reject(reason?: unknown): void }>

class AxiosService {
  private static _axios: AxiosInstance = Axios.create({
    baseURL: `${SERVER_URL}/api`,
    headers: {
      common: {
        'Content-Type': 'application/json',
      },
    },
    paramsSerializer: params => {
      return qs.stringify(params)
    },
  })

  private static _isRefreshing = false

  private static _failedQueue: FailedQueue = []

  static get instance(): AxiosInstance {
    return this._axios
  }

  static get failedQueue(): FailedQueue {
    return AxiosService._failedQueue
  }

  static set failedQueue(value: FailedQueue) {
    AxiosService._failedQueue = value
  }

  static get isRefreshing(): boolean {
    return AxiosService._isRefreshing
  }

  static set isRefreshing(value: boolean) {
    AxiosService._isRefreshing = value
  }

  static processQueue = (error: AxiosError | null = null, token: string | null = null) => {
    AxiosService.failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error)
      } else {
        prom.resolve(token)
      }
    })

    AxiosService.failedQueue = []
  }
}

AxiosService.instance.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    if (AuthService.hasToken()) {
      config.headers.common.Authorization = `Bearer ${AuthService.getAccessToken()}`
    }

    return config
  },
  (error: AxiosError): Promise<void> => {
    return Promise.reject(error)
  },
)

AxiosService.instance.interceptors.response.use(
  response => {
    return response
  },
  (error: AxiosError) => {
    const originalRequest: AxiosRequestConfig = error.config

    // @ts-ignore
    if (error?.response?.status === 401 && !originalRequest._retry) {
      if (AxiosService.isRefreshing) {
        return new Promise((resolve, reject) => {
          AxiosService.failedQueue.push({ resolve, reject })
        })
          .then(token => {
            originalRequest.headers.Authorization = `Bearer ${token}`
            return AxiosService.instance(originalRequest)
          })
          .catch((err: AxiosError) => {
            return Promise.reject(err)
          })
      }

      // @ts-ignore
      originalRequest._retry = true
      AxiosService.isRefreshing = true

      return new Promise((resolve, reject) => {
        AuthRepository.refreshToken()
          .then((response: LoginResponse) => {
            AuthService.setToken(response)

            originalRequest.headers.Authorization = `Bearer ${response.access_token}`
            AxiosService.processQueue(null, response.access_token)
            resolve(AxiosService.instance(originalRequest))
          })
          .catch(async (err: AxiosError) => {
            AuthService.removeToken()
            AxiosService.processQueue(err, null)

            if (__CLIENT__) {
              const { default: store } = await import('client/store')
              const state = store.getState()
              const { pathname } = state.router.location

              const isPrivate = matchRoutes(routing, pathname).some(({ route }) => route.private)

              store.dispatch(actions.reset())

              if (isPrivate) {
                store.dispatch(push(login.path))
              }
            }

            reject(err)
          })
          .finally(() => {
            AxiosService.isRefreshing = false
          })
      })
    }

    return Promise.reject(error)
  },
)

export default AxiosService.instance
