import { useCallback } from 'react'
import { Dispatch } from 'redux'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { matchRoutes } from 'react-router-config'

import AuthRepository from 'shared/features/Auth/AuthRepository'
import AuthService from 'shared/features/Auth/AuthService'
import { actions } from 'shared/features/Profile/ducks'
import { login as loginRoute } from 'shared/constants/routes'
import routing from 'shared/routing'
import consoleError from 'shared/utils/consoleError'

export default function useLogout(): () => Promise<void> {
  const dispatch: Dispatch = useDispatch()
  const { push } = useHistory()
  const location = useLocation()

  return useCallback(async (): Promise<void> => {
    try {
      await AuthRepository.logout()
    } catch (e) {
      consoleError(e)
    } finally {
      dispatch(actions.resetUser())
      AuthService.removeToken()
      matchRoutes(routing, location.pathname).forEach(({ route }) => {
        if (route.private) {
          push(loginRoute.path)
        }
      })
    }
  }, [dispatch, push, location])
}
