import React from 'react'
import { match } from 'react-router'
import { Dispatch } from 'redux'
import { Location, History } from 'history'

import { Permissions, Platform } from 'shared/constants'
import { AppState } from 'shared/store/reducer'

type LoadDataFromServer<L = History.PoorMansUnknown, M = Record<string, unknown>> = (ctx: {
  state: AppState
  dispatch: Dispatch
  location: Location<L>
  match: match<M>
  platformType: Platform
}) => void

export type Route<L = History.PoorMansUnknown, M = Record<string, unknown>> = {
  title: string
  path: string
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>
  permissions?: Array<Permissions>
  loadDataFromServer?: LoadDataFromServer<L, M>
  private?: boolean
  exact?: boolean
  startPage?: boolean
  errorPage?: boolean
}

export type GetRequestParams<T = number> = {
  page: T
  size: T
  sort?: NString
}

export type PaginationParams = {
  number: number
  size: number
  totalElements: number
  totalPages: number
}

export enum PaginationType {
  UNDER = 'UNDER',
  AROUND = 'AROUND',
}

export type Pageable<T> = {
  content: Array<T>
} & PaginationParams

export enum SocialNetwork {
  YANDEX = 'YANDEX',
  GOOGLE = 'GOOGLE',
  VK = 'VK',
  TELEGRAM = 'TELEGRAM',
}

type Account = {
  id: number
  userId: number
  accountType: SocialNetwork | 'FUTOPEDIA'
  accountTypeId: string
  primary: boolean
}

export type User = {
  id: number
  eaAccountName: string
  email?: string
  login: string
  birthday?: string
  permissions: Array<Permissions>
  picture?: string
  platform: Platform
  firstName: string
  lastName: string
  externalId?: number | null
  typeExternalId: SocialNetwork | null
  accounts: Array<Account>
  totalPoints: number
}

export enum Theme {
  light = 'light',
  dark = 'dark',
}

export type Nullable<T> = T | null | undefined

export type NString = Nullable<string>
