import React from 'react'
import { hydrate, render } from 'react-dom'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import { ConnectedRouter as Router } from 'connected-react-router'
import { loadableReady } from '@loadable/component'

import store from 'client/store'
import history from 'client/history'
import App from 'shared/App'

const renderer = __DEV__ ? render : hydrate
const rootEl: HTMLElement | null = document.getElementById('root')

const Wrapper: React.FC = ({ children }): React.ReactElement => (
  <HelmetProvider>
    <Provider store={store}>
      <Router history={history}>{React.Children.toArray(children)}</Router>
    </Provider>
  </HelmetProvider>
)

const Root: React.FC = (): React.ReactElement => (
  <Wrapper>
    <App />
  </Wrapper>
)

if (__CLIENT__) {
  renderer(<Root />, rootEl)
} else {
  loadableReady(() => {
    renderer(<Root />, rootEl)
  })
}

if (__DEV__ && module.hot) {
  module.hot.accept('shared/App', () => {
    // eslint-disable-next-line global-require
    const AppHot = require('shared/App').default

    renderer(
      <Wrapper>
        <AppHot />
      </Wrapper>,
      rootEl,
    )
  })
}
