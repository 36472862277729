import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import cn from 'clsx'

import { User } from 'shared/types'
import { profileOrders as profileOrdersRoute } from 'shared/constants/routes'
import { getPlatform, getUser } from 'shared/features/Profile/ducks/selectors'
import Typography from 'shared/components/Typography'
import { Platform } from 'shared/constants'

import styles from './UserInfo.module.css'

interface UserInfoProps {
  className?: string
  isPageBuilderFound: boolean
}

const UserInfo: React.FC<UserInfoProps> = ({ className, isPageBuilderFound }): React.ReactElement => {
  const user: User | undefined = useSelector(getUser)
  const history = useHistory()
  const platform: Platform = useSelector(getPlatform)
  const profilePath = profileOrdersRoute.path

  const handleGoToProfile = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      event.preventDefault()
      event.stopPropagation()

      history.push(profilePath)
    },
    [history, platform, isPageBuilderFound],
  )

  const userLogin = useMemo(() => {
    if (user) {
      if (user.firstName && user.lastName) {
        return `${user.firstName} ${user.lastName}`
      }
      return user.login
    }
    return null
  }, [user])

  return (
    <>
      {user && (
        <div className={cn(styles.user, className)} role="button" tabIndex={-1} onClick={handleGoToProfile}>
          <Typography className={styles.user__login}>{userLogin}</Typography>
        </div>
      )}
    </>
  )
}

export default UserInfo
