import axios from 'shared/utils/axios'
import { PostComplaint } from 'shared/features/Moderator/typings'

export default class ModeratorRepository {
  private static readonly endpoint = `${SERVER_URL}/personal/complaints`

  static addComplaint = async (requestComplaint: PostComplaint): Promise<string> => {
    const { data } = await axios.post<string>(ModeratorRepository.endpoint, requestComplaint)

    return data
  }
}
