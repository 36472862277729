import { useMemo } from 'react'
import { useLocation } from 'react-router'

import * as routes from 'shared/constants/routes'
import { REDIRECT_SEARCH_KEY } from 'shared/constants'

export default function useLoginWithRedirect() {
  const { pathname } = useLocation()

  return useMemo(
    () => ({
      pathname: routes.login.path,
      search: `${REDIRECT_SEARCH_KEY}=${pathname}`,
    }),
    [pathname],
  )
}
