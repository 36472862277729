import { ErrorNotice, Notice } from './types'

export enum NoticeType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export enum DefaultNoticeMessage {
  Error = 'Произошла ошибка',
  Success = 'Запрос выполнен успешно',
}

export const DEFAULT_ERROR_NOTICE: ErrorNotice = {
  message: DefaultNoticeMessage.Error,
  type: NoticeType.Error,
}

export const DEFAULT_SUCCESS_NOTICE: Notice = {
  message: DefaultNoticeMessage.Success,
  type: NoticeType.Success,
}
