import getScrollbarSize from 'shared/utils/getScrollbarSize'
import ownerDocument from 'shared/utils/ownerDocument'
import ownerWindow from 'shared/utils/ownerWindow'

import { ModalProps } from './Modal'

export type Modal = {
  modal: HTMLElement
  modalRef: HTMLElement | null
  mountNode: HTMLElement | null
}

interface Container {
  modals: Array<Modal>
  container: HTMLElement
  restore: null | (() => void)
  hiddenSiblingNodes: Array<HTMLElement>
}

// Is a vertical scrollbar displayed?
function isOverflowing(container: HTMLElement): boolean {
  const doc = ownerDocument(container)

  if (doc.body === container) {
    return ownerWindow(doc).innerWidth > doc.documentElement.clientWidth
  }

  return container.scrollHeight > container.clientHeight
}

export function ariaHidden(node: HTMLElement, show: boolean): void {
  if (show) {
    node.setAttribute('aria-hidden', 'true')
  } else {
    node.removeAttribute('aria-hidden')
  }
}

function getPaddingRight(node: HTMLElement): number {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return parseInt(window.getComputedStyle(node)['padding-right'], 10) || 0
}

function ariaHiddenSiblings(
  container: HTMLElement,
  mountNode: HTMLElement,
  currentNode: HTMLElement,
  nodesToExclude: Array<HTMLElement> = [],
  show: boolean,
) {
  const blacklist = [mountNode, currentNode, ...nodesToExclude]
  const blacklistTagNames = ['TEMPLATE', 'SCRIPT', 'STYLE']

  Array.prototype.forEach.call(container.children, (node: HTMLElement) => {
    if (node.nodeType === 1 && blacklist.indexOf(node) === -1 && blacklistTagNames.indexOf(node.tagName) === -1) {
      ariaHidden(node, show)
    }
  })
}

function findIndexOf(containerInfo: Array<Container>, callback: (item: Container) => boolean) {
  let idx = -1
  containerInfo.some((item: Container, index: number) => {
    if (callback(item)) {
      idx = index
      return true
    }
    return false
  })
  return idx
}

function handleContainer(containerInfo: Container, props: Partial<ModalProps>) {
  const restoreStyle: Array<{ value: string; key: string; el: HTMLElement }> = []
  const restorePaddings: Array<string> = []
  const { container } = containerInfo
  const fixedNodes: Array<HTMLElement> = []

  if (!props.disableScrollLock) {
    const overflowing = isOverflowing(container)

    // Improve Gatsby support
    // https://css-tricks.com/snippets/css/force-vertical-scrollbar/
    const parent: HTMLElement | null = container.parentElement
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const computedStyle: CSSStyleDeclaration = window.getComputedStyle(parent)

    const scrollContainer = parent?.nodeName === 'HTML' && computedStyle.overflowY === 'scroll' ? parent : container

    restoreStyle.push({
      value: scrollContainer.style.overflow,
      key: 'overflow',
      el: scrollContainer,
    })

    // Block the scroll even if no scrollbar is visible to account for mobile keyboard
    // screensize shrink.
    scrollContainer.style.overflow = 'hidden'

    if (overflowing) {
      const scrollbarSize = getScrollbarSize()

      restoreStyle.push({
        value: container.style.paddingRight,
        key: 'padding-right',
        el: container,
      })
      // Use computed style, here to get the real padding to add our scrollbar width.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      container.style['padding-right'] = `${getPaddingRight(container) + scrollbarSize}px`

      Array.prototype.forEach.call(fixedNodes, (node: HTMLElement) => {
        restorePaddings.push(node.style.paddingRight)
        // eslint-disable-next-line no-param-reassign
        node.style.paddingRight = `${getPaddingRight(node) + scrollbarSize}px`
      })
    }
  }

  return () => {
    if (fixedNodes) {
      Array.prototype.forEach.call(fixedNodes, (node: HTMLElement, i: number) => {
        if (restorePaddings[i]) {
          // eslint-disable-next-line no-param-reassign
          node.style.paddingRight = restorePaddings[i]
        } else {
          node.style.removeProperty('padding-right')
        }
      })
    }

    restoreStyle.forEach(({ value, el, key }) => {
      if (value) {
        el.style.setProperty(key, value)
      } else {
        el.style.removeProperty(key)
      }
    })
  }
}

function getHiddenSiblings(container: HTMLElement) {
  const hiddenSiblings: Array<HTMLElement> = []

  Array.prototype.forEach.call(container.children, (node: HTMLElement) => {
    if (node.getAttribute && node.getAttribute('aria-hidden') === 'true') {
      hiddenSiblings.push(node)
    }
  })

  return hiddenSiblings
}

/**
 * @ignore - do not document.
 *
 * Proper state management for containers and the modals in those containers.
 * Simplified, but inspired by react-overlay's ModalManager class.
 * Used by the Modal to ensure proper styling of containers.
 */
export default class ModalManager {
  private modals: Array<Modal> = []

  private containers: Array<Container> = []

  add(modal: Modal, container: HTMLElement) {
    let modalIndex = this.modals.indexOf(modal)

    if (modalIndex !== -1) {
      return modalIndex
    }

    modalIndex = this.modals.length
    this.modals.push(modal)

    // If the modal we are adding is already in the DOM.
    if (modal.modalRef) {
      ariaHidden(modal.modalRef, false)
    }

    const hiddenSiblingNodes = getHiddenSiblings(container)

    if (modal.mountNode && modal.modalRef) {
      ariaHiddenSiblings(container, modal.mountNode, modal.modalRef, hiddenSiblingNodes, true)
    }

    const containerIndex = findIndexOf(this.containers, (item: Container) => item.container === container)

    if (containerIndex !== -1) {
      this.containers[containerIndex].modals.push(modal)
      return modalIndex
    }

    this.containers.push({
      modals: [modal],
      container,
      restore: null,
      hiddenSiblingNodes,
    })

    return modalIndex
  }

  mount(modal: Modal, props: Partial<ModalProps>) {
    const containerIndex = findIndexOf(this.containers, (item: Container) => item.modals.indexOf(modal) !== -1)

    const containerInfo = this.containers[containerIndex]

    if (containerInfo && !containerInfo.restore) {
      containerInfo.restore = handleContainer(containerInfo, props)
    }
  }

  remove(modal: Modal) {
    const modalIndex = this.modals.indexOf(modal)

    if (modalIndex === -1) {
      return modalIndex
    }

    const containerIndex = findIndexOf(this.containers, (item: Container) => item.modals.indexOf(modal) !== -1)
    const containerInfo = this.containers[containerIndex]

    containerInfo.modals.splice(containerInfo.modals.indexOf(modal), 1)

    this.modals.splice(modalIndex, 1)

    // If that was the last modal in a container, clean up the container.
    if (containerInfo.modals.length === 0) {
      // The modal might be closed before it had the chance to be mounted in the DOM.
      if (containerInfo.restore) {
        containerInfo.restore()
      }

      if (modal.modalRef) {
        // In case the modal wasn't in the DOM yet.
        ariaHidden(modal.modalRef, true)
      }

      if (modal.modalRef && modal.mountNode) {
        ariaHiddenSiblings(
          containerInfo.container,
          modal.mountNode,
          modal.modalRef,
          containerInfo.hiddenSiblingNodes,
          false,
        )
      }

      this.containers.splice(containerIndex, 1)
    } else {
      // Otherwise make sure the next top modal is visible to a screen reader.
      const nextTop = containerInfo.modals[containerInfo.modals.length - 1]
      // as soon as a modal is adding its modalRef is undefined. it can't set
      // aria-hidden because the dom element doesn't exist either
      // when modal was unmounted before modalRef gets null
      if (nextTop.modalRef) {
        ariaHidden(nextTop.modalRef, false)
      }
    }

    return modalIndex
  }

  isTopModal(modal: Modal) {
    return this.modals.length > 0 && this.modals[this.modals.length - 1] === modal
  }
}
