import React from 'react'
import { useSelector } from 'react-redux'
import cn from 'clsx'

import { User } from 'shared/types'
import UserInfo from 'shared/core/components/UserInfo'
import { getVisibilitySignInBtn, getVisibilitySignUpBtn } from 'shared/core/ducks/selectors'
import useLogout from 'shared/hooks/useLogout'
import ButtonIcon from 'shared/components/ButtonIcon'
import ButtonIconLink from 'shared/components/ButtonIconLink'
import { getTotalPoints, getUser } from 'shared/features/Profile/ducks/selectors'
import { registration as registrationRoute } from 'shared/constants/routes'
import useLoginWithRedirect from 'shared/features/Auth/hooks/useLoginWithRedirect'
import { ReactComponent as LoginIcon } from 'shared/assets/icons/login.svg'
import { ReactComponent as UserIcon } from 'shared/assets/icons/user.svg'
import { ReactComponent as LogoutIcon } from 'shared/assets/icons/exit.svg'

import { TotalPoints } from '../TotalPoints'

import styles from './AuthControls.module.css'

interface AuthControlsProps {
  className?: string
}

const AuthControls: React.FC<AuthControlsProps> = ({ className }): React.ReactElement => {
  const isVisibleSignIn = useSelector(getVisibilitySignInBtn)
  const isVisibleSignUp = useSelector(getVisibilitySignUpBtn)

  const user: User | undefined = useSelector(getUser)
  const totalPoints = useSelector(getTotalPoints)
  const logIn = useLoginWithRedirect()
  const handleLogout = useLogout()

  return (
    <div className={cn(styles.authControls, className)}>
      <div className={styles.authControls__login}>
        {isVisibleSignIn && (
          <ButtonIconLink
            to={logIn}
            className={cn(styles.button, styles.button_unAuth)}
            label="Вход"
            classNameLabel={styles.button__label_unAuth}
            fullWidth
          >
            <LoginIcon />
          </ButtonIconLink>
        )}
        {isVisibleSignUp && (
          <ButtonIconLink
            to={registrationRoute.path}
            className={cn(styles.button, styles.button_unAuth)}
            classNameLabel={styles.button__label_unAuth}
            classNameIcon={styles.button__icon}
            label="Регистрация"
            fullWidth
          >
            <UserIcon />
          </ButtonIconLink>
        )}
      </div>

      {user && (
        <div className={styles.authControls__logout}>
          <TotalPoints totalPoints={totalPoints} className={styles.authControls__totalPoints} />
          <UserInfo isPageBuilderFound={false} className={styles.user} />
          <ButtonIcon className={cn(styles.button, styles.button_auth)} onClick={handleLogout}>
            <LogoutIcon />
          </ButtonIcon>
        </div>
      )}
    </div>
  )
}
export default AuthControls
