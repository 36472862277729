import { GetRequestParamsOrders, OrdersFilter } from './typings'

export const DEFAULT_GET_PARAMS_ORDERS: GetRequestParamsOrders = {
  page: 0,
  size: 10,
}

export const DEFAULT_ORDERS_FILTER: Partial<OrdersFilter> = {
  sortByDateDesc: true,
}
