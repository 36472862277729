import React from 'react'
import { YMInitializer } from 'react-yandex-metrika'

type YandexMetricProps = {
  id: number
}

const YandexMetric: React.FC<YandexMetricProps> = ({ id }): React.ReactElement => (
  <YMInitializer
    accounts={[id]}
    options={{
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    }}
  />
)

export default YandexMetric
