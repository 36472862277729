import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit'

import { ScrollTo } from '../constants'

export type HomeState = {
  scrollTo: ScrollTo | null
}

const initialState: HomeState = {
  scrollTo: null,
}

const home = createSlice({
  name: '@@home',
  initialState,
  reducers: {
    setScrollTo: (state: Draft<HomeState>, action: PayloadAction<ScrollTo | null>): void => {
      state.scrollTo = action.payload
    },
  },
})

export const { actions, reducer } = home
