import React from 'react'
import cn from 'clsx'

import Typography from 'shared/components/Typography'
import ButtonIcon from 'shared/components/ButtonIcon'
import { ReactComponent as CloseIcon } from 'shared/assets/icons/close.svg'

import styles from './Dialog.module.css'

interface DialogTitleProps {
  className?: string
  classNameTitle?: string
  classNameCloseIcon?: string
  children: React.ReactElement | React.ReactChildren | string
  onClose?(event: React.MouseEvent<HTMLElement, MouseEvent>): void
}

const DialogTitle: React.FC<DialogTitleProps> = ({
  className,
  classNameTitle,
  classNameCloseIcon,
  children,
  onClose,
}): React.ReactElement => (
  <div className={cn(styles.dialog__header, className)}>
    <Typography className={cn(styles.dialog__headerTitle, classNameTitle)} component="h2" uppercase>
      {children}
    </Typography>

    {onClose && (
      <ButtonIcon className={cn(styles.dialog__headerClose, classNameCloseIcon)} onClick={onClose} size="small">
        <CloseIcon />
      </ButtonIcon>
    )}
  </div>
)

export default DialogTitle
