import React, { useEffect } from 'react'

import { YandexAdsConfig } from './YandexAds'

const WidgetAds: React.FC<YandexAdsConfig> = ({ children, blockId, renderTo }) => {
  useEffect(() => {
    if (__CLIENT__) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.yaContextCb?.push(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.Ya.Context.AdvManager.renderWidget({
          renderTo,
          blockId,
        })
      })
    }
  }, [])

  return (
    <>
      <div id={renderTo} />
      {children}
    </>
  )
}

export default WidgetAds
