import React from 'react'

import Banner from 'shared/components/Banner'
import { newsListWidget } from 'shared/constants/ads'

const Widget: React.FC = (): React.ReactElement => {
  return <Banner {...newsListWidget} isWidget width={500} height={1} style={{ height: 'auto' }} />
}

export default Widget
