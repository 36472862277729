import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

import Typography, { TypographyProps } from 'shared/components/Typography'

const TypographyLink = React.forwardRef<HTMLElement, LinkProps & TypographyProps>(function TypographyLink(props, ref) {
  return <Typography ref={ref} component={Link} {...props} />
})

export default TypographyLink
