import React, { useCallback, useEffect, useRef } from 'react'

import Portal, { PortalProps } from 'shared/components/Portal'
import ownerDocument from 'shared/utils/ownerDocument'
import useEventCallback from 'shared/hooks/useEventCallback'
import useForkRef from 'shared/hooks/useForkRef'
import Key from 'shared/constants/key'

import ModalManager, { ariaHidden, Modal as TModal } from './ModalManager'

const defaultManager: ModalManager = new ModalManager()

export interface ModalProps {
  children: React.ReactElement
  open?: boolean
  testId?: string
  onRendered?: PortalProps['onRendered']
  className?: string
  style?: React.CSSProperties
  manager?: ModalManager
  disablePortal?: boolean
  disableScrollLock?: boolean
  keepMounted?: boolean
  closeAfterTransition?: boolean
  closeOnEsc?: boolean
  onClose?(): void
}

const Modal = React.forwardRef<HTMLElement, ModalProps>(function Modal(props, ref): React.ReactElement | null {
  const {
    children,
    open = false,
    testId,
    className,
    style,
    manager = defaultManager,
    disablePortal = false,
    disableScrollLock = false,
    keepMounted = false,
    closeAfterTransition = false,
    closeOnEsc = true,
    onClose,
    onRendered,
  } = props

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const modal = useRef<HTMLElement>({})
  const mountNodeRef = useRef<HTMLElement | null>(null)
  const modalRef = useRef<HTMLElement | null>(null)
  const handleRef = useForkRef<HTMLElement>(modalRef, ref) as React.Ref<HTMLDivElement>
  const getDoc = () => ownerDocument(mountNodeRef.current)

  const getModal = (): TModal => {
    return {
      modal: modal.current,
      modalRef: modalRef.current,
      mountNode: mountNodeRef.current,
    }
  }

  const handleMounted = useCallback((): void => {
    manager.mount(getModal(), { disableScrollLock })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    modalRef.current.scrollTop = 0
  }, [disableScrollLock, manager])

  const handleOpen = useCallback((): void => {
    manager.add(getModal(), getDoc().body)

    if (modalRef.current) {
      handleMounted()
    }
  }, [handleMounted, manager, modalRef])

  const isTopModal = useCallback(() => manager.isTopModal(getModal()), [manager])

  const handlePortalRef = useEventCallback((node: HTMLElement) => {
    mountNodeRef.current = node

    if (!node) {
      return
    }

    if (onRendered) {
      onRendered()
    }

    if (open && isTopModal()) {
      handleMounted()
      return
    }
    if (modalRef.current) {
      ariaHidden(modalRef.current, true)
    }
  })

  const handleClose = useCallback((): void => {
    manager.remove(getModal())
  }, [manager])

  useEffect(() => {
    return () => {
      handleClose()
    }
  }, [handleClose])

  useEffect(() => {
    if (open) {
      handleOpen()
      return
    }
    if (!closeAfterTransition) {
      handleClose()
    }
  }, [open, handleClose, closeAfterTransition, handleOpen])

  const handleEscape = useCallback(
    (event: KeyboardEvent): void => {
      if (event.key === Key.Escape && onClose && open) {
        onClose()
      }
    },
    [onClose, open],
  )

  useEffect(() => {
    if (closeOnEsc && open) {
      document.addEventListener('keydown', handleEscape, false)
    }

    return () => {
      document.removeEventListener('keydown', handleEscape, false)
    }
  }, [handleEscape, closeOnEsc, open])

  if (!keepMounted && !open) {
    return null
  }

  return (
    <Portal ref={handlePortalRef} onRendered={onRendered} disablePortal={disablePortal}>
      <div className={className} style={style} data-test={testId} ref={handleRef} role="presentation">
        {children}
      </div>
    </Portal>
  )
})

export default Modal
