import React, { useCallback } from 'react'
import cn from 'clsx'

import Modal from 'shared/components/Modal'
import Backdrop from 'shared/components/Backdrop'

import DialogTitle from './DialogTitle'
import styles from './Dialog.module.css'

export enum DialogSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface DialogProps {
  className?: string
  classNameModal?: string
  classNameModalHeader?: string
  classNameHeaderTitle?: string
  classNameBackdrop?: string
  classNameCloseIcon?: string
  children?: React.ReactElement | React.ReactChildren | string | Array<React.ReactElement | React.ReactNode | string>
  open?: boolean
  size?: keyof typeof DialogSize
  title?: React.ReactElement | React.ReactChildren | string
  invisibleBackdrop?: boolean
  disablePortal?: boolean
  closeOnBackdropClick?: boolean
  fullWidth?: boolean
  withBackdrop?: boolean
  onBackdropClick?(): void
  onRendered?(): void
  onClose?(): void
}

const Dialog = React.forwardRef<HTMLDivElement, DialogProps>(function Dialog(props, ref): React.ReactElement {
  const {
    className,
    classNameModal,
    classNameModalHeader,
    classNameHeaderTitle,
    classNameBackdrop,
    classNameCloseIcon,
    children,
    title,
    open = true,
    disablePortal = false,
    fullWidth = false,
    withBackdrop = true,
    size = DialogSize.medium,
    invisibleBackdrop = false,
    closeOnBackdropClick = true,
    onBackdropClick,
    onRendered,
    onClose,
  } = props

  const handleBackdrop = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      if (event.target !== event.currentTarget) {
        return
      }

      if (onBackdropClick) {
        onBackdropClick()
      }

      if (closeOnBackdropClick && onClose) {
        onClose()
      }
    },
    [onBackdropClick, onClose, closeOnBackdropClick],
  )

  const modalContent = withBackdrop ? (
    <Backdrop
      className={cn(styles.dialog__backdrop, classNameBackdrop)}
      onClick={handleBackdrop}
      invisible={invisibleBackdrop}
    >
      <div
        className={cn(styles.dialog__wrapper, classNameModal, {
          [styles.dialog__wrapper_small]: !fullWidth && size === DialogSize.small,
          [styles.dialog__wrapper_medium]: !fullWidth && size === DialogSize.medium,
          [styles.dialog__wrapper_large]: !fullWidth && size === DialogSize.large,
          [styles.dialog__wrapper_fullWidth]: fullWidth,
        })}
      >
        {title && (
          <DialogTitle
            className={classNameModalHeader}
            classNameTitle={classNameHeaderTitle}
            classNameCloseIcon={classNameCloseIcon}
            onClose={onClose}
          >
            {title}
          </DialogTitle>
        )}

        {React.Children.toArray(children)}
      </div>
    </Backdrop>
  ) : (
    <div
      className={cn(styles.dialog__wrapper, classNameModal, {
        [styles.dialog__wrapper_small]: !fullWidth && size === DialogSize.small,
        [styles.dialog__wrapper_medium]: !fullWidth && size === DialogSize.medium,
        [styles.dialog__wrapper_large]: !fullWidth && size === DialogSize.large,
        [styles.dialog__wrapper_fullWidth]: fullWidth,
      })}
    >
      {title && (
        <DialogTitle
          className={classNameModalHeader}
          classNameTitle={classNameHeaderTitle}
          classNameCloseIcon={classNameCloseIcon}
          onClose={onClose}
        >
          {title}
        </DialogTitle>
      )}

      {React.Children.toArray(children)}
    </div>
  )

  return (
    <Modal
      className={cn(styles.dialog, className, { [styles.dialog__withoutBackdrop]: !withBackdrop })}
      open={open}
      onClose={onClose}
      onRendered={onRendered}
      ref={ref}
      disablePortal={disablePortal}
    >
      {modalContent}
    </Modal>
  )
})

export default Dialog
