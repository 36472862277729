import React, { memo } from 'react'
import cn from 'clsx'

import AuthControls from 'shared/core/components/AuthControls'
import ButtonIcon from 'shared/components/ButtonIcon'
import { ReactComponent as BurgerIcon } from 'shared/assets/icons/burger_navigation.svg'

import styles from './Header.module.css'
import { HeaderLogo } from './HeaderLogo'
import { HeaderNav } from './HeaderNav'

interface HeaderProps {
  className?: string
  placeholder?: string
  goToPlayerCard?: boolean
  renderMobileBanner?: React.ReactElement
  classNameAutoCompleteItem?: string
}

const Header: React.FC<HeaderProps> = ({ className }): React.ReactElement => {
  return (
    <header className={cn(styles.header, className)}>
      <ButtonIcon classNameIcon={styles.navigation__btnIcon} className={styles.navigation__btn}>
        <BurgerIcon />
      </ButtonIcon>
      <HeaderLogo />
      <span className={styles.divider} />
      <HeaderNav />
      <AuthControls className={styles.authContainer__authControl} />
    </header>
  )
}

export default memo(Header)
