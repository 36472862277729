import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { actions as buyActions } from 'shared/features/Buy/ducks'
import { getAuthorized } from 'shared/features/Profile/ducks/selectors'
import { getIsOrderPaid, getOrderUuid } from 'shared/features/Buy/ducks/selectors'

import { actions as wsActions } from '../ducks'
import { createConnection, emitter } from '../WebSocketService'
import { MessageMethod, MessageType } from '../constants'
import { ResponseMessage, ResponseMessageParams } from '../types'

export default () => {
  const dispatch = useDispatch()
  const uuid: string = useSelector(getOrderUuid)
  const isAuthorized: boolean = useSelector(getAuthorized)
  const isOrderPaid = useSelector(getIsOrderPaid)

  useEffect((): void => {
    if (!isAuthorized) {
      return
    }
    if (!!uuid && isOrderPaid) {
      createConnection(uuid)
    }
  }, [uuid, isAuthorized, isOrderPaid])

  useEffect(() => {
    emitter.on(MessageType.params, (data: ResponseMessage<ResponseMessageParams>) => {
      if (!data) {
        return
      }
      const {
        method,
        params: [buyInfo],
      } = data
      if (method === MessageMethod.updateOrder) {
        dispatch(buyActions.setBuyInfo(buyInfo))
      }
      if (method === MessageMethod.orderCanceled) {
        dispatch(buyActions.setMessageMethod(method))
      }
    })

    emitter.on(MessageType.onopen, (isOpen: boolean) => {
      dispatch(wsActions.setIsOpenConnection(isOpen))
    })
  }, [emitter])
}
