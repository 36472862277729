import { createSelector } from 'reselect'
import { Location, Search } from 'history'
import qs, { ParsedQuery } from 'query-string'

import { AppState } from 'shared/store/reducer'
import { User } from 'shared/types'
import {
  home as homeRoute,
  registration as registrationRoute,
  passwordRecovery as passwordRecoveryRoute,
  login as loginRoute,
} from 'shared/constants/routes'

import { SearchKey } from '../constants'

const getLocation = (state: AppState): Location => state.router.location
const getSearch = (state: AppState): Search => state.router.location.search
const getUser = (state: AppState): User | undefined => state.profile.user

export const getVisibilitySignInBtn = createSelector<AppState, User | undefined, Location, boolean>(
  getUser,
  getLocation,
  (user, location): boolean => {
    return !user && location.pathname !== loginRoute.path
  },
)

export const getVisibilitySignUpBtn = createSelector<AppState, User | undefined, Location, boolean>(
  getUser,
  getLocation,
  (user, location): boolean => {
    return !user && location.pathname !== registrationRoute.path
  },
)

const getParsedQuery = createSelector(getSearch, search => {
  return qs.parse(search)
})

const getQueryByKey = (parsedQuery: ParsedQuery, key: SearchKey): string | null => {
  if (key in parsedQuery) {
    const token = parsedQuery[key]

    return typeof token === 'string' ? token : null
  }

  return null
}

export const getTokenQuery = createSelector(getParsedQuery, parsedQuery => getQueryByKey(parsedQuery, SearchKey.Token))

export const getRedirectPath = createSelector(getParsedQuery, parsedQuery => {
  const redirectPath = getQueryByKey(parsedQuery, SearchKey.RedirectPath)

  if (redirectPath && redirectPath !== registrationRoute.path && redirectPath !== passwordRecoveryRoute.path) {
    return redirectPath
  }

  return homeRoute.path
})
