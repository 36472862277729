import React from 'react'
import { Switch, Route as RouteComponent, RouteProps, SwitchProps } from 'react-router-dom'
import { RouteConfigComponentProps } from 'react-router-config'

import PrivateRoute from 'shared/core/components/PrivateRoute'
import routing from 'shared/routing'

export default function renderRoutes(routes: typeof routing, switchProps: SwitchProps = {}) {
  return (
    <Switch {...switchProps}>
      {routes.map((route, key) => {
        const routeProps: RouteProps = {
          path: route.path,
          exact: route.exact,
          strict: route.strict,
          // eslint-disable-next-line react/display-name
          render: (props: RouteConfigComponentProps) => {
            if (route.render) {
              return route.render({ ...props, route })
            }

            if (route.component) {
              return <route.component {...props} route={route} />
            }

            return null
          },
        }

        if (route.private) {
          return <PrivateRoute key={route.key || key} {...routeProps} permissions={route.permissions} />
        }

        return <RouteComponent key={route.key || key} {...routeProps} />
      })}
    </Switch>
  )
}
