import { ReactComponent as NumberRounded1Icon } from 'shared/assets/icons/number-rounded-1.svg'
import { ReactComponent as NumberRounded2Icon } from 'shared/assets/icons/number-rounded-2.svg'
import { ReactComponent as NumberRounded3Icon } from 'shared/assets/icons/number-rounded-3.svg'
import { ReactComponent as NumberRounded1FillIcon } from 'shared/assets/icons/number-rounded-fill-1.svg'
import { ReactComponent as NumberRounded2FillIcon } from 'shared/assets/icons/number-rounded-fill-2.svg'
import { ReactComponent as NumberRounded3FillIcon } from 'shared/assets/icons/number-rounded-fill-3.svg'

import { Step } from './types'

export enum BuyStatus {
  start = 'start',
  preparing = 'preparing',
  buying = 'buying',
  selling = 'selling',
  complete = 'complete',
  cancelled = 'cancelled',
}

export enum BuyPlatform {
  Pc = 'pc',
  Console = 'ps4',
}

export const platformDisplayNames: Record<BuyPlatform, string> = {
  [BuyPlatform.Pc]: 'PC',
  [BuyPlatform.Console]: 'PS',
}

export enum LotStatus {
  bought = 'bought',
  ready = 'ready',
  selling = 'selling',
  sold = 'sold',
}

export enum StepName {
  Payment = 'Payment',
  Step1 = 'Step1',
  Step2 = 'Step2',
  Step3 = 'Step3',
  Final = 'Final',
}

export enum StepTitle {
  PayInfo = 'Сколько монет у вас уже есть на балансе EA?',
  Payment = 'Ваш заказ',
  Step2 = 'Установите параметры для продажи',
  Step3 = 'Найдите своих выставленных игроков',
  Final = 'Ваши монеты доставлены, спасибо за заказ!',
}

type MainSteps = keyof Pick<typeof StepName, 'Step1' | 'Step2' | 'Step3'>

export enum MainStepsTabName {
  Step1 = 'Купите игрока',
  Step2 = 'Выставьте игрока на продажу',
  Step3 = 'Найдите своего игрока',
}

export const mainStepsTabIcon: Record<MainSteps, React.FC<React.SVGProps<SVGSVGElement>>> = {
  Step1: NumberRounded1Icon,
  Step2: NumberRounded2Icon,
  Step3: NumberRounded3Icon,
}

export const mainStepsTabActiveIcon: Record<MainSteps, React.FC<React.SVGProps<SVGSVGElement>>> = {
  Step1: NumberRounded1FillIcon,
  Step2: NumberRounded2FillIcon,
  Step3: NumberRounded3FillIcon,
}

export const steps: Step[] = [
  { name: StepName.Payment, isViewed: false, withTabButton: false },
  {
    name: StepName.Step1,
    isViewed: false,
    tabButtonName: MainStepsTabName.Step1,
    withTabButton: true,
  },
  {
    name: StepName.Step2,
    isViewed: false,
    tabButtonName: MainStepsTabName.Step2,
    withTabButton: true,
  },
  {
    name: StepName.Step3,
    isViewed: false,
    tabButtonName: MainStepsTabName.Step3,
    withTabButton: true,
  },
  {
    name: StepName.Final,
    isViewed: false,
    withTabButton: false,
  },
]

export enum PaymentStatus {
  Wait = 'wait',
  Success = 'preauth',
  Error = 'error',
  ErrorPay = 'error_pay',
  ErrorCheck = 'error_check',
}

export enum PaymentError {
  Create = 'Ошибка при создании платежа',
  Transaction = 'Ошибка платежа',
}

export enum CreateOrderError {
  ChannelUnvailable = 'Все каналы заняты, попробуйте совершить покупку позже',
  ServerUnvailable = 'Сервис временно недоступен, попробуйте позже',
}
