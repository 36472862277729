import qs from 'query-string'

import { Pageable } from 'shared/types'
import axios from 'shared/utils/axios'

import { CreateReview, GetRequestParamsReviews, Review } from './types'

export class ReviewsRepository {
  public static fetchEndpoint = ''

  public static async fetchReviews(params: GetRequestParamsReviews): Promise<Pageable<Review>> {
    const { data } = await axios.get<Pageable<Review>>(`${SERVER_URL}/api/reviews?${qs.stringify(params)}`)
    return data
  }

  public static async createReview(review: CreateReview): Promise<Review> {
    const { data } = await axios.post<Review>(`${SERVER_URL}/personal/reviews`, review)
    return data
  }
}
