import { call, put, select, takeLatest } from 'redux-saga/effects'

import AuthService from 'shared/features/Auth/AuthService'
import { getErrorNotice } from 'shared/features/Notification/utils'

import BuyRepository from '../BuyRepository'
import { CreateOrderResponse, ExchangeRatesResponse, Order, PaymentResponse } from '../types'
import { PaymentError } from '../constants'
import { createOrderErrorHandler } from '../utils/createOrderErrorHandler'

import { getPayment } from './selectors'

import { actions } from './index'

function* getLastOrder() {
  try {
    const data: CreateOrderResponse = yield call(BuyRepository.fetchLastOrder)

    if (data) {
      const { ftpPayment, moneyPayment, order } = data

      if (ftpPayment || moneyPayment) {
        yield put(
          actions.createPaymentSuccess({
            ftpPayment,
            moneyPayment,
          }),
        )
      }
      yield put(actions.getLastOrderSuccess(order))
    } else {
      yield put(actions.getLastOrderSuccess(null))
    }
  } catch (error) {
    yield put(actions.getLastOrderError())
  }
}

function* createOrder(action: ReturnType<typeof actions.createOrder>) {
  try {
    yield call(BuyRepository.createOrder, action.payload)
    yield call(getLastOrder)
    yield put(actions.createOrderSuccess())
  } catch (e) {
    yield put(actions.createOrderErrorNotification(createOrderErrorHandler(e)))
  }
}

function* cancelOrder(action: ReturnType<typeof actions.cancelOrder>) {
  try {
    yield call(BuyRepository.cancelOrder, action.payload)
    yield put(actions.cancelOrderSuccess())
    yield put(actions.reset())
  } catch (e) {
    const lastOrder: Order | '' = yield call(BuyRepository.fetchLastOrder)
    if (!lastOrder) {
      yield put(actions.reset())
    }
    yield put(actions.cancelOrderError())
  }
}

function* getExchangeRates() {
  try {
    const data: ExchangeRatesResponse = yield call(BuyRepository.fetchExchangeRates)
    yield put(actions.getExchangeRatesSuccess(data))
  } catch (error) {
    yield put(actions.getExchangeRatesError())
  }
}

function* handlePayment(action: ReturnType<typeof actions.handlePayment>) {
  try {
    const payment: PaymentResponse = yield select(getPayment)

    if (!payment) {
      const data: PaymentResponse = yield call(BuyRepository.createPayment, action.payload)
      yield put(actions.createPaymentSuccess(data))
      yield put(actions.setPaymentUrl(data.moneyPayment?.paymentUrl || null))
      return
    }

    const data: PaymentResponse = yield call(BuyRepository.updatePayment, action.payload)
    yield put(actions.createPaymentSuccess(data))
    yield put(actions.setPaymentUrl(data.moneyPayment?.paymentUrl || null))
  } catch (error) {
    yield put(actions.createPaymentErrorNotification(getErrorNotice(PaymentError.Create)))
  }
}

function* actualizePayment(action: ReturnType<typeof actions.actualizePayment>) {
  try {
    const data: PaymentResponse = yield call(BuyRepository.actualizePayment, action.payload)
    yield put(actions.createPaymentSuccess(data))
  } catch (error) {
    yield put(actions.createPaymentErrorNotification(getErrorNotice(PaymentError.Create)))
  }
}

function* cancelPayment(action: ReturnType<typeof actions.cancelPayment>) {
  try {
    yield call(BuyRepository.cancelPayment, action.payload)
  } catch (error) {
    yield put(actions.createPaymentErrorNotification(getErrorNotice(PaymentError.Create)))
  }
}

function* preloadInfo() {
  try {
    yield call(getExchangeRates)

    if (AuthService.hasToken()) {
      yield call(getLastOrder)
    }

    yield put(actions.preloadInfoSuccess())
  } catch (error) {
    yield put(actions.preloadInfoError())
  }
}

export default function* actionWatcher() {
  yield takeLatest(actions.createOrder.type, createOrder)
  yield takeLatest(actions.cancelOrder.type, cancelOrder)
  yield takeLatest(actions.preloadInfo.type, preloadInfo)
  yield takeLatest(actions.getLastOrder.type, getLastOrder)
  yield takeLatest(actions.getExchangeRates.type, getExchangeRates)
  yield takeLatest(actions.handlePayment.type, handlePayment)
  yield takeLatest(actions.actualizePayment.type, actualizePayment)
  yield takeLatest(actions.cancelPayment.type, cancelPayment)
}
