import { call, put, takeLatest } from 'redux-saga/effects'

import { getErrorNotice } from 'shared/features/Notification/utils'
import { Pageable } from 'shared/types'

import { CREATE_REVIEW_ERROR } from '../constants'
import { ReviewsRepository } from '../ReviewsRepository'
import { Review } from '../types'

import { actions } from './index'

function* fetchReviews(action: ReturnType<typeof actions.fetchReviews>) {
  try {
    const reviews: Pageable<Review> = yield call(ReviewsRepository.fetchReviews, action.payload)
    yield put(actions.fetchReviewsSuccess(reviews))
  } catch (e) {
    yield put(actions.fetchReviewsError())
  }
}

function* createReview(action: ReturnType<typeof actions.createReview>) {
  try {
    yield call(ReviewsRepository.createReview, action.payload)
    yield put(actions.createReviewSuccess())
  } catch (e) {
    yield put(actions.createReviewErrorNotification(getErrorNotice(CREATE_REVIEW_ERROR)))
  }
}

export default function* actionWatcher() {
  yield takeLatest(actions.fetchReviews.type, fetchReviews)
  yield takeLatest(actions.createReview.type, createReview)
}
