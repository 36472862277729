import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit'

import { PostComplaint } from 'shared/features/Moderator/typings'

export type ModeratorState = {
  isCreating: boolean
}

const initialState: ModeratorState = {
  isCreating: false,
}

const moderatorSlice = createSlice({
  name: '@@moderator',
  initialState,
  reducers: {
    addComplaint: (state: Draft<ModeratorState>, _action: PayloadAction<PostComplaint>): void => {
      state.isCreating = true
    },
    addComplaintSuccess: (state: Draft<ModeratorState>): void => {
      state.isCreating = false
    },
    addComplaintError: (state: Draft<ModeratorState>): void => {
      state.isCreating = false
    },
    resetState: (): ModeratorState => initialState,
  },
})

export const { actions, reducer } = moderatorSlice
