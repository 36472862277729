import { hot } from 'react-hot-loader/root'
import React, { useEffect } from 'react'
import { Dispatch } from 'redux'
import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import routing from 'shared/routing'
import styles from 'shared/App.module.css'
import AuthService from 'shared/features/Auth/AuthService'
import { actions as actionsProfile } from 'shared/features/Profile/ducks'
import preloadFonts from 'shared/utils/getPreloadFonts'
import YandexMetric from 'shared/analytics/YandexMetric'
import GoogleAnalytics from 'shared/analytics/GoogleAnalytics'
import renderRoutes from 'shared/routing/renderRoutes'

import getFileExtension from './utils/getFileExtension'
import useWebSocket from './features/WebSocket/hooks/useWebSocket'
import { Notification } from './features/Notification/components/Notification/Notification'

const App: React.FC = (): React.ReactElement => {
  const dispatch: Dispatch = useDispatch()
  useWebSocket()

  useEffect(() => {
    const { body } = document
    let timer!: NodeJS.Timeout
    window.addEventListener(
      'scroll',
      function clear() {
        clearTimeout(timer)
        if (!body.classList.contains('disable-hover')) {
          body.classList.add('disable-hover')
        }
        timer = setTimeout(function removeDisable() {
          body.classList.remove('disable-hover')
        }, 500)
      },
      false,
    )
  }, [])

  useEffect(() => {
    if (AuthService.hasToken()) {
      dispatch(actionsProfile.getAccount())
    }
  }, [dispatch])

  return (
    <>
      <Helmet titleTemplate="FuttyCoin - %s" defaultTitle="Приложение компаньон для Futcoins">
        <html lang="ru" className={styles.theme} />
        <base target="_blank" href="/" />
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Сервис FuttyCoin это быстрый и надежный способ доставки игровых монет FIFA 22"
        />
        <meta name="theme-color" content="#3f2a7b" />
        <meta name="apple-mobile-web-app-title" content="FuttyCoin" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#3f2a7b" />
        <link rel="manifest" href="/manifest.json" />

        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />

        <link rel="apple-touch-icon" sizes="57x57" href="/apple-touch-icon/apple-touch-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/apple-touch-icon/apple-touch-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/apple-touch-icon/apple-touch-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon/apple-touch-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/apple-touch-icon/apple-touch-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/apple-touch-icon/apple-touch-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-touch-icon/apple-touch-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon/apple-touch-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon/apple-touch-icon-180x180.png" />
        <link rel="apple-touch-icon-precomposed" href="/apple-touch-icon/apple-touch-icon-precomposed.png" />

        <link rel="preconnect" href={process.env.SERVER_URL} />

        {preloadFonts.map((href: string) => (
          <link
            key={href}
            rel="preload"
            href={href}
            as="font"
            type={`font/${getFileExtension(href)}`}
            crossOrigin="anonymous"
          />
        ))}
        <body className={styles.body} />
      </Helmet>

      <div className={styles.root} id="APP">
        {renderRoutes(routing)}
        <Notification />
      </div>

      {YANDEX_METRIKA_ID && <YandexMetric id={Number(YANDEX_METRIKA_ID)} />}

      {GOOGLE_ANALYTICS_ID && <GoogleAnalytics id={GOOGLE_ANALYTICS_ID} />}
    </>
  )
}

export default __PROD__ ? App : hot(App)
